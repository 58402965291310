import ChartDataLabels from 'chartjs-plugin-datalabels'
import {externalTooltipHandler} from '../../utils/handlers.js'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ChartDataLabels,
  Legend
);

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function CohortUsageChart({cohortUsage}){

  const options = {
    layout: {
      padding: {
        top: 10
      }
    },
    responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
          callbacks:{
            label: function(tooltipItem, data) {
                //get metadata of tooltipItem index
                let item = cohortUsage[tooltipItem.dataIndex]
                return [
                  "Doctor Visits: "+item.doctor_visits,
                  "Labs / Imaging: "+item.labs_imaging,
                  "ER Visits: "+item.emergency_room,
                  "Hospitalizations: "+item.hospitalizations,
                  "Outpatient Procedures: "+item.outpatient_procedures,
                ]
            },
        },
        external: externalTooltipHandler
      },
      datalabels: {
        display: true,
        color: "black",
        formatter: function(value, context) {
          return '$' + numberWithCommas(Math.round(value));
        },
        padding: {
          // bottom: 20
        },
        font: {
          weight: 'normal',
          size: 14,
          family: "Merriweather"
        },
        anchor: "end",
        offset: -25,
        align: "start"
      }
    },
    scales: {
      // to remove the labels
      x: {
        ticks: {
          font: {
            size: 16,
            weight: 'normal',
            family: "Merriweather"
          },
          display: false,
        },

        // to remove the x-axis grid
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      // to remove the y-axis labels
      y: {
        ticks: {
          display: false,
          beginAtZero: true,
        },
        // to remove the y-axis grid
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    }
  }

  const labels = ['Lowest Usage', 'Low Usage', 'Median Usage', 'High Usage', 'Highest Usage']

  const bars = cohortUsage.map(profile => Math.round((profile.tot_expenditure + Number.EPSILON) * 100) / 100)

  const data = {
    labels,
    datasets: [
      {
        data: bars,
        minBarLength: 7,
        backgroundColor: ['#1F7401', '#054075', '#EDA00C','#DC9595', '#AC0202'],
      },
    ],
  };

  return (

    <div className="cohort-usage-chart">
      <div className="graph mt-10 px-5">
      <span id="chartjs-tooltip"></span>
       <Bar className="mt-5" options={options} data={data}></Bar>
      </div>
      <div className="grid grid-cols-5 mt-2 gap-x-4 px-6">
          <div className="tooltip-container border-dashed ml-1 border-b pb-1 border-gray-600">
            <div className="tooltip">
              <h2 className="tick ml-3">{cohortUsage[0].label}</h2>
              <i className="icon ri-arrow-right-line" />
              <span className="tooltip-drop tooltip-bottom">
                {cohortUsage[0].desc}
              </span>
            </div>
          </div>
          <div className="tooltip-container border-dashed ml-1 border-b pb-1 border-gray-600">
            <div className="tooltip">
              <h2 className="tick ml-3">{cohortUsage[1].label}</h2>
              <i className="icon ri-arrow-right-line" />
              <span className="tooltip-drop tooltip-bottom">
                {cohortUsage[1].desc}
              </span>
            </div>
          </div>
          <div className="tooltip-container border-dashed ml-1 border-b pb-1 border-gray-600">
            <div className="tooltip">
              <h2 className="tick ml-1.5">{cohortUsage[2].label}</h2>
              <i className="icon ri-arrow-right-line" />
              <span className="tooltip-drop tooltip-bottom">
                {cohortUsage[2].desc}
              </span>
            </div>
          </div>
          <div className="tooltip-container border-dashed ml-1 border-b pb-1 border-gray-600">
            <div className="tooltip">
              <h2 className="tick">{cohortUsage[3].label}</h2>
              <i className="icon ri-arrow-right-line" />
              <span className="tooltip-drop tooltip-bottom">
                {cohortUsage[3].desc}
              </span>
            </div>
          </div>
          <div className="tooltip-container border-dashed ml-1 border-b pb-1 border-gray-600">
            <div className="tooltip">
              <h2 className="tick">{cohortUsage[4].label}</h2>
              <i className="icon ri-arrow-right-line" />
              <span className="tooltip-drop tooltip-bottom">
                {cohortUsage[4].desc}
              </span>
            </div>
          </div>
       </div>
    </div>
  )
}

export default CohortUsageChart