import classNames from 'classnames'
import Navigation from '../Navigation'
import info from '../../assets/icons/info.svg'
import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"


function AboutYouLocation(props){

  const [searchParams] = useSearchParams()
  const [state, setState] = useState({})

  const [age, setAge] = useState(null)
  const [sex, setSex] = useState('M')
  const [tobacco, setTobacco] = useState(false)
  const [eligible, setEligible] = useState(false)
  const [pregnant, setPregnant] = useState(false)
  const [errors, setErrors] = useState({})

  let navigate = useNavigate()

  useEffect(() => {
    const appState = decode(searchParams.get('state'))
    setState(appState)
    if(!appState || appState.location === undefined || 
      appState.location === null){
      previousRoute(navigate)
    }else{
      window.scrollTo(0, 0)
      updateRoute(appState)
    }
  }, [])

  function updateRoute(appState){
    if(appState && appState.primary !== undefined){
      setAge(appState.primary.age)
      setSex(appState.primary.gender)
      setTobacco(appState.primary.uses_tobacco)
      setEligible(appState.primary.aptc_eligible)
      setPregnant(appState.primary.is_pregnant)
    }
  }

  function nextRoute(navigate){
    if(age !== null && age !== '' && age){
      if(age >= 18 && age < 65){
        const state = getState()
        const encodedState = encode(state)
        navigate('/savings?state='+encodedState)
      }else{
        window.alert("Based on your age, there may be additional considerations in selecting health insurance. Email us directly using the “Contact Us” button on the top right")
      }
    }else{
      setErrors({
        age: [
          'Please provide a valid age'
        ]
      })
    }
  }

  function previousRoute(navigate){
    const updatedState = getState()
    const encodedState = encode(updatedState)
    navigate('/about/location?state='+encodedState)
  }

  function getState(){
    const updatedState = state
    updatedState.primary = {
      age,
      gender: sex,
      uses_tobacco: tobacco,
      aptc_eligible: eligible,
      is_pregnant: pregnant,
    }
    return updatedState
  }

  function encode(payload){
    return encodeURIComponent(JSON.stringify(payload))
  }

  function decode(payload){
    return JSON.parse(payload)
  }

  var sexInputClass = function(option, custom = ''){
    return classNames('px-10 py-2.5 rounded-lg w-36 ' + custom, {
      'text-white bg-lumos-blue z-10': sex === option,
      'text-black bg-white border border-lumos-blue': sex !== option
    })
  }

  return (
    <div className="main hidden md:block form max-w-2xl mx-auto my-14">
      <Navigation/>
      <div className="step px-10 max-w-2xl mx-auto">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">Tell us a bit about yourself</h2>
          <p className="text-lg mt-2">We need this information to pull plans you’re eligible for  from ACA</p>
        </div>
        <div className="about-you rounded-lg mt-8 border border-lumos-blue p-5">
          <h2 className="text-lg text-lumos-blue font-semibold">About You</h2>
          <div className="inputs flex mt-4 items-center justify-between">
            <div className="age">
              <label htmlFor="age" className="font-semibold text-lg">Age</label>
              <input type="number" min="1" onChange={(e) => setAge(e.target.value)} value={age} className="border w-28 border-lumos-blue block py-2 px-3 mt-2 rounded-lg"/>
              {errors && errors.age && errors.age.map((error, index) =>
                <span key={'errors-age-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
              )}
            </div>
            <div className="sex text-center">
              <label htmlFor="age" className="age font-semibold text-lg">Sex</label>
              <div className="sex switch ml-10 flex mt-2">
                <button onClick={() => setSex("M")} className={sexInputClass('M')}>Male</button>
                <button onClick={() => setSex("F")} className={sexInputClass('F', '-ml-2')}>Female</button>
              </div>
            </div>
          </div>
          <div className="mt-5 mb-2">
            <p className="text-lg font-semibold">Select all that apply</p>
            <label htmlFor="tobacco" className={classNames('input cursor-pointer flex items-center p-3 mt-2 rounded-lg border border-lumos-blue hover:bg-lumos-light', {
                'bg-lumos-light': tobacco,
              })}>
              <input id="tobacco" type="checkbox" checked={tobacco} name="tobacco" value={tobacco} onChange={(e) => setTobacco(!tobacco)}/>
              <label htmlFor="tobacco" className="ml-2 cursor-pointer w-full">I’ve used tobacco 4 or more times for past 6 months</label>
              <div className="tooltip-container ml-auto">
                  <div className="tooltip">
                      <span className="ml-auto"><img src={info} alt="Info"/></span>
                      <i className="icon ri-arrow-right-line" />
                    <span className="tooltip-drop tooltip-right">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    </span>
                  </div>
              </div>
            </label>
            <label htmlFor="eligible" className={classNames('input cursor-pointer flex items-center p-3 mt-2 rounded-lg border border-lumos-blue hover:bg-lumos-light', {
                'bg-lumos-light': eligible,
              })}>
              <input id="eligible" type="checkbox" checked={eligible} name="eligible" value={eligible} onChange={(e) => setEligible(!eligible)}/>
              <label htmlFor="eligible" className="ml-2 cursor-pointer">I’m eligible for coverage through a job, Medicaid, CHIP</label>
              <div className="tooltip-container ml-auto">
                  <div className="tooltip">
                      <span className="ml-auto"><img src={info} alt="Info"/></span>
                      <i className="icon ri-arrow-right-line" />
                    <span className="tooltip-drop tooltip-right">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                    </span>
                  </div>
              </div>
            </label>
            {sex === 'F' && 
            <label htmlFor="pregnant" className={classNames('input cursor-pointer flex items-center p-3 mt-2 rounded-lg border border-lumos-blue hover:bg-lumos-light', {
                            'bg-lumos-light': pregnant,
                          })}>
              <input id="pregnant" type="checkbox" name="pregnant" value={pregnant} onChange={(e) => setPregnant(!pregnant)}/>
              <label htmlFor="pregnant" className="ml-2 cursor-pointer">Pregnant</label>
              <div className="tooltip-container ml-auto">
                  <div className="tooltip">
                      <span className="ml-auto"><img src={info} alt="Info"/></span>
                      <i className="icon ri-arrow-right-line" />
                    <span className="tooltip-drop tooltip-right">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                    </span>
                  </div>
              </div>
            </label>}
          </div>
        </div>
        <div className="add-spouse rounded-lg mt-4 border border-lumos-blue p-3 text-center">
          <p className="text-lg font-semibold text-lumos-inactive">Add my spouse (Feature coming soon)</p>
        </div>
        <div className="add-spouse rounded-lg my-4 border border-lumos-blue p-3 text-center">
          <p className="text-lg font-semibold text-lumos-inactive">Add a dependent (Feature coming soon)</p>
        </div>
        <div className="actions flex items-center justify-between mt-10">
          <button onClick={() => previousRoute(navigate)} className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded">
            Back
          </button>
          <button onClick={() => nextRoute(navigate)} className="w-64 p-4 bg-lumos-blue border border-lumos-blue text-white text-3xl rounded">
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

export default AboutYouLocation