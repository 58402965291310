import _ from 'lodash'
import axios from 'axios'
import Select from 'react-select'
import classNames from 'classnames'
import Navigation from '../Navigation'
import info from '../../assets/icons/info.svg'
import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"


function ProvidersCoverage(props){

  const [searchParams] = useSearchParams()
  const [onlyPreferredProviders, setOnlyPreferredProviders] = useState(false)
  const [errors, setErrors] = useState({})
  const [search, setSearch] = useState('')
  const [providerOptions, setProviderOptions] = useState([])
  const [providers, setProviders] = useState([])
  const [state, setState] = useState({})
  let navigate = useNavigate()

  useEffect(() => {
    const appState = decode(searchParams.get('state'))
    setState(appState)
    if(stateHasLocation(appState) && stateHasInfo(appState) && stateHasSavings(appState)){
      window.scrollTo(0, 0)
      updateRoute(appState)
    }else{
      previousRoute(navigate)
    }
  }, [])

  function stateHasLocation(appState){
    return appState && appState.location !== undefined && appState.location !== null
  }

  function stateHasInfo(appState){
    return appState && appState.primary &&
             appState.primary.age &&
             appState.primary.gender
  }

  function stateHasSavings(appState){
    return appState && appState.household_people &&
               appState.household_income
  }

  function updateRoute(appState){
    if(appState.providers){
      setProviders(appState.providers)
      setOnlyPreferredProviders(appState.only_preferred_providers)
    }
  }

  function getState(){
    const updatedState = state
    const stateProviders = providers.map(option => {
      let updatedOption = {
        provider: {
          npi: option.provider.npi,
          name: option.provider.name
        }
      }
      return updatedOption
    })
    updatedState.providers = stateProviders
    updatedState.only_preferred_providers = onlyPreferredProviders

    return updatedState
  }
  
  function previousRoute(navigate){
    const updatedState = getState()
    const encodedState = encode(updatedState)
    navigate('/savings?state='+encodedState)
  }

  function nextRoute(navigate){
    const state = getState()
    const encodedState = encode(state)
    navigate('/coverage/drugs?state='+encodedState)
  }

  function decode(payload){
    return JSON.parse(payload)
  }

  function encode(payload){
    return encodeURIComponent(JSON.stringify(payload))
  }

  function pushToProviders(provider){
    const updatedProviders = providers
    updatedProviders.push(provider)
    setProviders(updatedProviders)
    setProviderOptions([])
    setSearch("")
  }

  function removeFromProviders(option){
    const updatedProviders = providers.filter(item => {
      if(item.provider.npi !== option.provider.npi){
        return true
      }
    })
    setProviders(updatedProviders)
  }

  function searchProviders(e){
      setSearch(e.target.value)
      if(e.target.value && e.target.value.length > 3){
        const query = e.target.value
        const payload = {
          params: {
            apikey: process.env.REACT_APP_CMS_API_KEY,
            q: query,
            zipcode: state.location.zipcode,
          }
        }
        const debouncedSearch = _.debounce(() => {
          axios.get(process.env.REACT_APP_CMS_API_URL+'/coverage/search', payload).then(response => {
            if(response.status === 200){
              console.log(response.data)
              setProviderOptions(response.data.providers)
            }
          })
        }, 500)
        debouncedSearch()
      }
  }

  var providersClass = function(option, custom = ''){
    return classNames('cursor-pointer px-5 rounded-lg py-5 hover:bg-lumos-blue hover:text-white ' + custom, {
      'border': onlyPreferredProviders !== option,
      'bg-lumos-blue text-white': onlyPreferredProviders === option
    })
  }

  return (
    <div className="main hidden md:block form max-w-2xl mx-auto my-14">
      <Navigation/>
      <div className="step">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">Let’s understand your coverage needs. <br/> Do you have any preferred doctors, hospitals, or pharmacies?</h2>
          <p className="text-lg mt-8">We’ll use this information to tailor our recommendations and show you which plans cover your preferences</p>
        </div>
        <div className="inputs px-16 mt-10">
          <div className="input flex mt-2">
            <input value={search} onChange={(e) => searchProviders(e)} type="text" placeholder="Enter the name of a doctor, specialist, hospital, or pharmacy" className="w-full border border-lumos-blue block py-3 px-3 rounded-lg"/>
          </div>
        </div>
        <div className="results px-16">
          <div className="scroller border-lumos-blue overflow-scroll max-h-80">
            {providerOptions && !!providerOptions.length && providerOptions.map((option, index) => <div key={"drug-options-"+index} className={"result border cursor-pointer relative rounded-lg px-5 py-3 border-black border-t-0 flex items-center justify-between"}>
                <div className="option-content">
                  <h2 className="text-base font-semibold">{option.provider.name} 
                    {option && <span className="text-sm font-normal"> {option.provider.taxonomy}</span>}
                  </h2>
                  <div className="capitalize text-sm">
                    <span>{option.address.street1.toLowerCase()}, </span>
                    <span>{option.address.city.toLowerCase()}, </span>
                    <span>{option.address.state}</span>  
                  </div>
                </div>
                <button onClick={() => {
                    pushToProviders(option)
                  }} className="px-5 py-3 text-white bg-lumos-blue rounded text-sm">Add</button>
              </div>)}        
          </div>
        </div>
        <div className="selected px-16 mt-5">
          {!!providers.length && providers.map((option, index) => <div key={"selected-"+index} className="result mt-2 border relative rounded-lg px-5 py-3 border-black">
            <h2 className="text-base capitalize font-semibold">{option.provider.name.toLowerCase()} 
            </h2>
            <span onClick={(e) => removeFromProviders(option)} className="absolute top-1 right-3 text-lg cursor-pointer">&times;</span>
          </div>)} 
        </div>
{/*        <div className="results px-16 mt-5">
          {!!providerOptions.length && providerOptions.map((option, index) => <div key={"results-"+index} onClick={(e) => pushToProviders(option)}  className="result mt-2 border relative rounded-lg px-5 py-3 border-black hover:bg-lumos-blue hover:text-white cursor-pointer">
            <h2 className="text-base font-semibold">{option.provider.name} 
              {option && <span className="text-sm font-normal"> {option.provider.taxonomy}</span>}
            </h2>
              <div className="capitalize text-sm">
                <span>{option.address.street1.toLowerCase()}, </span>
                <span>{option.address.city.toLowerCase()}, </span>
                <span>{option.address.state}</span>  
              </div>
          </div>)}        
        </div>*/}
        {/*REMOVE "false &&" from the statement below to add back functionality*/}
        {false && providers && !!providers.length && <div className="recommend-options">
          <div className="items-center text-center mt-10">
            <p className="font-semibold inline text-lg">Do you want us to recommend plans that may lower your cost but do not cover you preferred doctors, hospitals, or pharmacies? </p>
              <div className="tooltip-container">
                <div className="tooltip">
                    <span className="ml-auto"><img src={info} alt="Info"/></span>
                    <i className="icon ri-arrow-right-line" />
                  <span className="tooltip-drop tooltip-right">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                  </span>
                </div>
              </div>
          </div>
          <div className="options grid grid-cols-2 gap-8 mt-10">
            <div onClick={() => setOnlyPreferredProviders(true)} className={providersClass(true)}>
              <p className="text-center">Only recommend plans that cover these preferences</p>
            </div>
            <div onClick={() => setOnlyPreferredProviders(false)} className={providersClass(false)}>
              <p className="text-center">I'm open to recommendations that do not cover these preferences</p>
            </div>
          </div>
          {onlyPreferredProviders && <div className="banner border border-black mt-5 bg-lumos-light p-5 text-sm text-center">Gotcha! We’ll only recommend plans that cover these preferences but if you change your mind, you can update this setting from the Results page. </div>}
          {!onlyPreferredProviders && <div className="banner border border-black mt-5 bg-lumos-light p-5 text-sm text-center">Great! You may see recommended plans that do not cover all these preferences. If you change your mind, you’ll be able to update settings from the Results page. </div>}
        </div>}
        <div className="actions flex  items-center justify-between mt-16">
          <button onClick={() => previousRoute(navigate)} className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded">
            Back
          </button>
          <button onClick={() => {
              nextRoute(navigate)
            }} className="w-64 p-4 bg-lumos-blue border border-lumos-blue text-white text-3xl rounded">
            {providers && !!providers.length && <span>Next</span>}
            {providers && !providers.length && <span>Skip this step</span>}
          </button>
        </div>
      </div>
  </div>
  )
}

export default ProvidersCoverage
