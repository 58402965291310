import ChartDataLabels from 'chartjs-plugin-datalabels'
import {externalTooltipHandler} from '../../utils/handlers.js'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ChartDataLabels,
  Legend
);

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function HealthStatusChart({payload}){

  function getRangeValue(item, field){
    return item[field].length > 1 ? item[field][0] + "-" +item[field][1] : "~"+item[field][0]
  }

  function commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function getPriceRangeValue(item, field){
    return item[field].length > 1 ? "$" + commas(item[field][0]) + "-" + "$"+commas(item[field][1]) : "$"+commas(item[field][0])
  }

  const options = {
    layout: {
      padding: {
        top: 20
      }
    },
    responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
          padding: 10,
          width: 300,
          callbacks:{
            label: function(tooltipItem) {
                let item = payload.usage[tooltipItem.dataIndex]
                return [
                  "Doctor Visits: "+getRangeValue(item, 'doctor_visits'),
                  "Labs / Imaging: "+getRangeValue(item, 'labs_imaging'),
                  "ER Visits: "+getRangeValue(item, 'emergency_room'),
                  "Hospitalizations: "+getRangeValue(item, 'hospitalizations'),
                  "Outpatient Procedures: "+getRangeValue(item, 'outpatient_procedures'),
                  "Total Billed Cost: "+getPriceRangeValue(item, 'tot_expenditure')
                ]
            },
            title: function(){
              return "Likely Usage"
            }
        },
        external: externalTooltipHandler
      },
      datalabels: {
        display: true,
        color: "black",
        formatter: function(value, context) {
          return Math.round(value) + '%';
        },
        padding: {
          // bottom: 20
        },
        font: {
          weight: 'normal',
          size: 14,
          family: "Merriweather"
        },
        anchor: "end",
        offset: 0,
        align: "end"
      }
    },
    scales: {
      // to remove the labels
      x: {
        ticks: {
          font: {
            size: 16,
            weight: 'normal',
            family: "Merriweather"
          },
          display: true,
        },

        // to remove the x-axis grid
        grid: {
          drawBorder: true,
          borderColor: 'black',
          display: false,
        },
      },
      // to remove the y-axis labels
      y: {
        ticks: {
          display: false,
          beginAtZero: true,
        },
        // to remove the y-axis grid
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    }
  }

  function titleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  const labels = payload.usage.map(item => titleCase(item.health_status.replaceAll('_', ' ').toLowerCase()))

  const bars = payload.usage.map(item => item.share * 100)

  const data = {
    labels,
    datasets: [
      {
        data: bars,
        minBarLength: 7,
        backgroundColor: ['#1F7401', '#054075', '#EDA00C','#DC9595', '#AC0202'],
      },
    ],
  };

  return (

    <div className="cohort-usage-chart">
      <div className="graph mt-10">
        <span id="chartjs-tooltip" style={{width: 1300}}></span>
        <Bar className="mt-5" options={options} data={data}></Bar>
      </div>
    </div>
  )
}

export default HealthStatusChart