import classNames from 'classnames'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'


function Navigation(){

  const [currentRoute, setCurrentRoute] = useState('')
  const location = useLocation()
  const routesHierarchy = [
    'about',
    'savings',
    'coverage',
    'usage',
    'other',
  ]

  useEffect(() => {
    setCurrentRoute(location.pathname.split('/')[1])
  }, [])

  var navTitleClass = function(route){
    return classNames('text-lg font-bold', {
      'text-lumos-inactive': currentRoute !== route
    })
  }
  var navBarClass = function(route){
    return classNames('border-2 mt-2', {
      'bg-lumos-blue text-lumos-blue border-lumos-blue': currentRoute === route,
      'bg-lumos-inactive border-lumos-inactive': currentRoute !== route
    })
  }

  return (
      <div className="navigation flex grid grid-cols-5 gap-3 max-w-2xl mx-auto">
        <div className="step-status text-center">
          <a className={navTitleClass('about')}>About You</a>
          <hr className={navBarClass('about')}/>
        </div>
        <div className="step-status text-center">
          <a className={navTitleClass('savings')}>Savings</a>
          <hr className={navBarClass('savings')}/>
        </div>
        <div className="step-status text-center">
          <a className={navTitleClass('coverage')}>Coverage</a>
          <hr className={navBarClass('coverage')}/>
        </div>
        <div className="step-status text-center">
          <a className={navTitleClass('usage')}>Usage</a>
          <hr className={navBarClass('usage')}/>
        </div>
        <div className="step-status text-center">
          <a className={navTitleClass('other')}>Other</a>
          <hr className={navBarClass('other')}/>
        </div>
    </div>
  )
}

export default Navigation