import React, { useState, useRef, useEffect } from 'react'
import Select from 'react-select'
import classNames from 'classnames'
import CohortUsageChart from './CohortUsageChart'
import ComparisonCostOutcomesChart from './ComparisonCostOutcomesChart'
import ComparisonTableCoverage from './ComparisonTableCoverage'
import TrueCostBreakDown from './TrueCostBreakDown'
import arrowDown from '../../assets/icons/arrow-down.svg'

function PlanComparison({plans, setStage, cohortUsage, subsidy}){
  
  const [switchOption, setSwitchOption] = useState("true_cost")
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0)
  const [docsContent, setDocsContent] = useState([])
  const [drugsContent, setDrugsContent] = useState([])

  function getLogo(name){
    let url = "/logos/"+name
    return url+".png"
  }

  var optionClass = function(option, custom = ''){
    return classNames('cursor-pointer px-4 rounded py-3 ' + custom, {
      'border': switchOption !== option,
      'bg-lumos-blue text-white': switchOption === option
    })
  }
  
  const options = plans.map((item, index) => {
    return {"label": item.issuer + " - " + item.plan_name, value: index}
  })

  const costOutcomesRef = useRef(null)

  const executeScroll = () => costOutcomesRef.current.scrollIntoView()    

  const docsHeaders = plans.map(item => {
    return {
      label: item.plan_name,
      logo: getLogo(item.issuer),
      alt: item.issuer
    }
  })

  useEffect(() => {
    const updatedDocsContent = plans[0].provider_coverage.map(doctor => {
      return {title: doctor.name, "data": []}
    })

    plans.map(plan => {
      plan.provider_coverage.map((doctor, index) => {
        updatedDocsContent[index].data.push({
          label: doctor.is_covered ? "Covered" : "Not Covered",
          class: doctor.is_covered ? "text-lumos-green" : "text-red-600"
        })
        return doctor
      })
      return plan
    })
    const updatedDrugsContent = plans[0].drug_coverage.map(drug => {
      return {title: drug.name, "data": []}
    })

    plans.map(plan => {
      plan.drug_coverage.map((drug, index) => {
        updatedDrugsContent[index].data.push({
          label: drug.is_covered ? "Covered" : "Not Covered",
          class: drug.is_covered ? "text-lumos-green" : "text-red-600"
        })
        return drug
      })
      return plan
    })

    setDocsContent(updatedDocsContent)
    setDrugsContent(updatedDrugsContent)

  }, [plans])

  function atLeastOneCoverageOption(){
    for (var i = 0; i < plans.length; i++) {
      let plan = plans[i]
      if(plan.drug_coverage.length > 0) return true
      if(plan.provider_coverage.length > 0) return true
    }
    return false
  }


  return (
    <div className="plan">
      <div className="header-actions flex justify-between items-center mt-16">
      </div>
      <div className="plans-switch info mt-12 text-center px-36">
        <div className="switch flex grid grid-cols-2 mx-10 px-16">
          <button onClick={() => setSwitchOption('true_cost')} className={optionClass('true_cost')}>
            True Cost
          </button>
          {atLeastOneCoverageOption() && <button onClick={() => {setSwitchOption('coverage')}} className={optionClass('coverage')}>
            Coverage
          </button>}
          {!atLeastOneCoverageOption() && <button disabled className="cursor-pointer px-4 rounded py-3 bg-gray-400 text-white cursor-not-allowed">
            Coverage
          </button>}
        </div>
      </div>

     {switchOption === "coverage" && <div className="true-cost px-5">
       {docsContent.length > 0 && <h3 className="text-xl text-lumos-blue font-semibold text-center mt-14">Which of my doctors, hospitals, and pharmacies does each plan cover?</h3>}
       {docsContent.length > 0 && <ComparisonTableCoverage listsKey="docs" data={{
          headers: docsHeaders,
          content: docsContent
        }}/>}
       {drugsContent.length > 0 && <h3 className="text-xl text-lumos-blue font-semibold text-center mt-10">Which of my drugs does each plan cover and how much will they cost?</h3>}
       {drugsContent.length > 0 && <ComparisonTableCoverage listsKey="drugs" data={{
          headers: docsHeaders,
          content: drugsContent
        }}/>}
     </div>}

     {switchOption === "true_cost" && <div className="true-cost px-16">
        <div className="true-cost">
           <h2 className="text-lumos-blue text-xl mt-10 font-semibold">My Cohort’s Usage</h2>
           <hr className="border border-lumos-blue w-full mt-2"/>
           <h3 className="text-lg px-36 text-lumos-blue text-center mt-10">To compare, let's first understand how much do <span className="underline cursor-pointer">people like you</span> spend on healthcare each year (before insurance cost sharing)</h3>
            <CohortUsageChart cohortUsage={cohortUsage}/>
            <div className="banner border border-black mt-16 bg-lumos-light p-5 mx-4">
              <h4 className="font-semibold">
                Why does healthcare spend vary so much for people like me? 
              </h4>
              Although we identified the set of people like you based on your age, gender, and health status, healthcare outcomes can still vary. For instance, you may be exceptionally healthy and lucky over the next year in which case you may have to visit the doctor infrequently and as a result spend very little on healthcare. However, if you’re unlucky and are met with some injury or some unforeseen condition,  then you may have to spend a lot. The different groups (e.g. lowest, median, highest) represent different outcomes for people like you. Calculating the true cost and picking the right plan requires we account for all the scenarios appropriately
            </div>
           <div className="actions flex items-center justify-between mt-14">
              <button onClick={() => {
                executeScroll()
              }} className="w-full p-4 bg-lumos-blue border border-lumos-blue text-white text-xl rounded flex justify-center items-center">
                See How Each Plan Helps You Pay For These Expenses <img className="ml-4 w-6" src={arrowDown} alt=""/>
              </button>
            </div>
         </div>
         <div className="cost" ref={costOutcomesRef}>
            <h2 className="text-lumos-blue text-xl mt-16 font-semibold">Cost Outcomes</h2>
            <hr className="border border-lumos-blue w-full mt-2"/>
            <h3 className="text-lg px-36 text-lumos-blue text-center mt-10">The chart below shows how much you’d pay under each plan if your spending was similar to people like you</h3>
            <ComparisonCostOutcomesChart plans={plans}></ComparisonCostOutcomesChart>
            <div className="table-section text-center mt-16">
              <h2 className="text-lumos-blue text-xl mt-10 font-semibold">The table below shows you the detailed breakdown of the true cost for each plan. Select a plan to see the breakdown of true cost</h2>
              <div className="select-box border border-black p-8 mt-12 text-left">
                <span className="font-semibold">Select a Plan:</span>
                <Select
                  defaultValue={options[0]}
                  placeholder="Select Plan"
                  onChange={(option) => setSelectedPlanIndex(option.value)}
                  className="w-full mt-2 text-left"
                  options={options}
                />
                <TrueCostBreakDown subsidy={subsidy} plan={plans[selectedPlanIndex]}/>
                <div className="banner border border-black mt-2 bg-lumos-light p-5">
                  <h4 className="font-semibold">
                    Your estimated annual average true cost for this plan, accounting for all usage scenarios, is <span>${plans[selectedPlanIndex].true_cost}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
       </div>}
    </div>
  )
}

export default PlanComparison