import React, { useState, useRef } from 'react'
import classNames from 'classnames'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import CohortUsageChart from './sections/CohortUsageChart'
import CostOutcomesChart from './sections/CostOutcomesChart'
import TrueCostBreakDown from './sections/TrueCostBreakDown'
import DoctorsCoverage from './DoctorsCoverage'
import arrowDown from '../assets/icons/arrow-down.svg'
import ReactStars from 'react-stars'


function Plan({plan, setStage, rating, locationState, cohortUsage, subsidy}){

  const [expandables, setExpandables] = useState({
    'doctors': false,
    'drugs': false
  })

  const supportedStates = ["TX", "FL"] 

  function getLogo(name){
    let url = "/logos/"+name
    return url+".png"
  }

  function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

  function handleExpandables(expandable, value){

    let updatedExpandables = {...expandables}

    updatedExpandables[expandable] = value

    setExpandables(updatedExpandables)

  }

  const [switchOption, setSwitchOption] = useState("summary")
  const [trueCostStage, setTrueCostStage] = useState("usage")

  var optionClass = function(option, custom = ''){
    return classNames('cursor-pointer px-4 rounded py-3 ' + custom, {
      'border': switchOption !== option,
      'bg-lumos-blue text-white': switchOption === option
    })
  }

  var metalClass = function(option, custom = ''){
    return classNames('metal-level flex items-center' + custom, {
      'text-red-600': option === "Catastrophic",
      'text-bronze': option === "Bronze",
      'text-silver': option === "Silver",
      'text-gold': option === "Gold",
      'text-platinum': option === "Platinum",
    })
  }

  function getGovTaxCredit(premium, subsidy){
    return Math.round((Math.max(0, premium - subsidy) + Number.EPSILON) * 100) / 100
  }

  const costOutcomesRef = useRef(null)

  const executeScroll = () => costOutcomesRef.current.scrollIntoView()    

  return (
    <div className="plan">
      <div className="header-actions flex justify-between items-center mt-16">
        <div className="logo flex justify-between items-start">
          <button onClick={() => {
            window.scrollTo(0,0)
            setStage('plans')
          }} className="text-lg text-lumos-blue">&#x2190; Back to Recommendations</button>
        </div>
        <div className="logo flex justify-between items-start">
            {supportedStates.includes(locationState) && <button onClick={() => {
                window.scrollTo(0,0)
                setStage('enroll')
              }} className="px-8 py-2 bg-green-700 ml-8 text-white rounded-lg">
                Enroll In Plan
              </button>}
            {!supportedStates.includes(locationState) && 
              <div className="tooltip-container ml-8">
                  <div className="tooltip">
                      <button className="px-8 py-2 bg-gray-400 text-gray-200 rounded-lg cursor-not-allowed " disabled>
                        Enroll In Plan
                      </button>
                    <span className="tooltip-drop tooltip-bottom">
                      We are unable to process enrollments in your state. If you’d like to enroll in this plan, you can do so by enrolling in the plan directly through www.healthcare.gov
                    </span>
                  </div>
              </div>}
        </div>
      </div>
      <div className="mt-5 text-left">
        <img className="w-36" src={getLogo(plan.issuer)} alt={plan.issuer}/>
        <h2 className="text-2xl text-lumos-blue mt-8">{plan.plan_name}</h2>
      </div>
      <div className="plans-switch info mt-12 text-center px-36">
        <div className="switch flex grid grid-cols-2 mx-10 px-16">
          <button onClick={() => setSwitchOption('summary')} className={optionClass('summary')}>
            Plan Summary
          </button>
          <button onClick={() => setSwitchOption('true_cost')} className={optionClass('true_cost')}>
            True Cost
          </button>
        </div>
      </div>
     {switchOption === "summary" && <div className="plan-summary px-16">
         <h2 className="text-lumos-blue text-xl mt-10">Plan Summary</h2>
         <hr className="border border-lumos-blue w-full mt-2"/>
         <div className="table grid grid-cols-2 mt-4 items-center">
           <div className="mt-4 font-semibold">Estimated Annual All-in Cost</div>
           <div className="flex mt-4 justify-between items-center">
             <div className="value">
               ${plan.true_cost}
             </div>
           </div>
           <hr className="col-span-2 mt-4 border-0.5 border-black"/>
           <div className="mt-4 font-semibold">Monthly Premiums</div>
           <div className="flex mt-4 justify-between items-center">
             <div className="value">
               ${getGovTaxCredit(plan.premium, subsidy)}/mo <span className="text-red-600 ml-4 text-sm">(${plan.premium}/mo without tax credits)</span>
             </div>
           </div>
           <hr className="col-span-2 mt-4 border-0.5 border-black"/>
           <div className="mt-4 font-semibold">Network Type</div>
           <div className="flex mt-4 justify-between items-center">
             <div className="value">
               <span>
                 {plan.type}
               </span>
             </div>
           </div>
           <hr className="col-span-2 mt-4 border-0.5 border-black"/>
           <div className="mt-4 font-semibold">Referral Required To See A Specialist?</div>
           <div className="flex mt-4 justify-between items-center">
             <div className="value">
               {plan.referral_desc}
             </div>
           </div>
           <hr className="col-span-2 mt-4 border-0.5 border-black"/>
           <div className="mt-4 font-semibold">HSA Eligible?</div>
           <div className="flex mt-4 justify-between items-center">
             <div className="value">
               <span>
                 {plan.hsa_eligible ? "Yes" : "No"}
               </span>
             </div>
           </div>
           {plan.provider_coverage && plan.provider_coverage.length > 0 && <hr className="col-span-2 mt-4 border-0.5 border-black"/>}
           {plan.provider_coverage && plan.provider_coverage.length > 0 && <div className="mt-4 font-semibold">Doctors Covered</div>}
           {plan.provider_coverage && plan.provider_coverage.length > 0 && <div className="flex mt-4 justify-between items-center">
              <div className="value">
                {plan.provider_coverage.filter(item => item.is_covered).length} out of {plan.provider_coverage.length} covered
              </div>
              <div className="expandable">
                 <button onClick={() => handleExpandables('doctors', !expandables.doctors)}>
                  {!expandables.doctors && <span>Expand <span>&#8595;</span></span>}
                  {expandables.doctors && <span>Collapse <span>&#8593;</span></span>}
                 </button>
              </div>
            </div>}
           {expandables.doctors && <hr className="col-span-2 mt-6"/>}
           {expandables.doctors && <div className="expand-items mt-2">
              {plan.provider_coverage && plan.provider_coverage.length > 0 && plan.provider_coverage.map((provider, index) => <div key={"providers-"+index} className="row px-2 py-4 flex items-center">
                 <h1 className="text-gray-700">{provider.name}</h1> 
                 {provider.is_covered && <span className="text-green-600 ml-4">Takes this plan</span>}
                 {!provider.is_covered && <span className="text-red-600 ml-4">Not covered</span>}
               </div>)}
            </div>}
           {plan.drug_coverage && plan.drug_coverage.length > 0 && <hr className="col-span-2 mt-4 border-0.5 border-black"/>}
           {plan.drug_coverage && plan.drug_coverage.length > 0 && <div className="mt-4 font-semibold">Drugs Covered</div>}
           {plan.drug_coverage && plan.drug_coverage.length > 0 && <div className="flex mt-4 justify-between items-center">
              <div className="value">
                {plan.drug_coverage.filter(item => item.is_covered).length} out of {plan.provider_coverage.length} covered
              </div>
              <div className="expandable">
                 <button onClick={() => handleExpandables('drugs', !expandables.drugs)}>
                  {!expandables.drugs && <span>Expand <span>&#8595;</span></span>}
                  {expandables.drugs && <span>Collapse <span>&#8593;</span></span>}
                 </button>
              </div>
            </div>}
           {expandables.drugs && <hr className="col-span-2 mt-6"/>}
           {expandables.drugs && <div className="expand-items mt-2">
              {plan.drug_coverage && plan.drug_coverage.length > 0 && plan.drug_coverage.map((provider, index) => <div key={"providers-"+index} className="row px-2 py-4 flex items-center">
                 <h1 className="text-gray-700">{provider.name}</h1> 
                 {provider.is_covered && <span className="text-green-600 ml-4">Covered</span>}
                 {!provider.is_covered && <span className="text-red-600 ml-4">Not covered</span>}
               </div>)}
            </div>}
           <hr className="col-span-2 mt-4 border-0.5 border-black"/>
           <div className="mt-4 font-semibold">Plan Rating</div>
           <div className="flex mt-4 items-center">
             <div className="value">
               {rating.global_rating > 0 && <ReactStars
                edit={false}
                value={rating.global_rating}
                count={5}
                size={24}
                color2={'#ffd700'}/>}
             </div>
             {rating.global_rating > 0 && <span className="ml-5">{rating.global_rating}/5</span>}
             {rating.global_rating === 0 && <span>Rating is Not Available</span>}
             <div className="expandable">
             </div>
           </div>
           <hr className="col-span-2 mt-4 border-0.5 border-black"/>
           <div className="mt-4 font-semibold">Plan Level</div>
           <div className="flex mt-4 justify-between items-center">
             <div className="value">
              <div className={metalClass(plan.metal_level)}>
                <span className="text-xs mr-3">&#11044;</span> 
                <span className="text-lg">{plan.metal_level}</span>
              </div>
             </div>
           </div>
           <hr className="col-span-2 mt-4 border-0.5 border-black"/>
           <div className="mt-4 font-semibold">{Object.keys(plan.deductible)[0]}</div>
           <div className="flex mt-4 justify-between items-center">
             <div className="value">
              {Object.keys(plan.deductible)[0] && <div className="row grid grid-cols-2 items-center">
                  <h2 className="text-base">${numberWithCommas(Object.values(plan.deductible)[0])}</h2>
              </div>}
             </div>
           </div>
           {Object.keys(plan.deductible)[1] && <hr className="col-span-2 mt-4 border-0.5 border-black"/>}
           {Object.keys(plan.deductible)[1] && <div className="mt-4 font-semibold">{Object.keys(plan.deductible)[1]}</div>}
           {Object.keys(plan.deductible)[1] && <div className="flex mt-4 justify-between items-center">
              <div className="value">
               {Object.keys(plan.deductible)[1] && <div className="row grid grid-cols-2 items-center">
                   <h2 className="text-base">${numberWithCommas(Object.values(plan.deductible)[1])}</h2>
               </div>}
              </div>
            </div>}
           <hr className="col-span-2 mt-4 border-0.5 border-black"/>
           <div className="mt-4 font-semibold">Out-of-Pocket Maximum</div>
           <div className="flex mt-4 justify-between items-center">
             <div className="value">
               ${numberWithCommas(plan.oop_max)}
             </div>
           </div>
           <hr className="col-span-2 mt-4 border-0.5 border-black"/>
           <div className="mt-4 font-semibold">Plan Documents</div>
           <div className="flex mt-4 justify-between items-center">
             <div className="value">
               {plan.links && plan.links.map(link => <div className="link">
                 <a target="_blank" className="text-blue-500 ml-2" href={link.url}>
                   &#128279;
                   <span className="ml-2">{link.name}</span>                   
                 </a>
               </div>)}
             </div>
           </div>
           <hr className="col-span-2 mt-4 border-0.5 border-black"/>
         </div>
       </div>}

     {switchOption === "true_cost" && <div className="true-cost px-16">
        {true && <div className="usage">
           <h2 className="text-lumos-blue text-xl mt-10 font-semibold">My Cohort’s Usage</h2>
           <hr className="border border-lumos-blue w-full mt-2"/>
           <h3 className="text-lg px-36 text-lumos-blue text-center mt-10">How much do <span className="underline cursor-pointer">people like me</span> spend on healthcare each year (before insurance cost sharing)</h3>
            <CohortUsageChart cohortUsage={cohortUsage}/>
            <div className="banner border border-black mt-16 bg-lumos-light p-5 mx-4">
              <h4 className="font-semibold">
                Why does healthcare spend vary so much for people like me? 
              </h4>
              Although we identified the set of people like you based on your age, gender, and health status, healthcare outcomes can still vary. For instance, you may be exceptionally healthy and lucky over the next year in which case you may have to visit the doctor infrequently and as a result spend very little on healthcare. However, if you’re unlucky and are met with some injury or some unforeseen condition,  then you may have to spend a lot. The different groups (e.g. lowest, median, highest) represent different outcomes for people like you. Calculating the true cost and picking the right plan requires we account for all the scenarios appropriately
            </div>
           <div className="actions flex items-center justify-between mt-14">
              <button onClick={() => {
                executeScroll()
              }} className="w-full p-4 bg-lumos-blue border border-lumos-blue text-white text-xl rounded flex justify-center items-center">
                See How This Plan Can Help You Pay For These Expenses <img className="ml-4 w-6" src={arrowDown} alt=""/>
              </button>
            </div>
         </div>}
         {true && <div className="cost" ref={costOutcomesRef}>
            <h2 className="text-lumos-blue text-xl mt-16 font-semibold">Cost Outcomes</h2>
            <hr className="border border-lumos-blue w-full mt-2"/>
            <h3 className="text-lg px-36 text-lumos-blue text-center mt-10">How much would I pay under this plan if my spending was similar to people like me</h3>
            <CostOutcomesChart plan={plan}></CostOutcomesChart>
            <div className="table-section text-center mt-16">
              <h2 className="text-lumos-blue text-xl mt-10 font-semibold">What’s the breakdown of true cost for this plan?</h2>
              <TrueCostBreakDown subsidy={subsidy} plan={plan}/>
            </div>
            <div className="banner border border-black mt-10 bg-lumos-light p-5 mx-4">
              <h4 className="font-semibold">
                Your estimated annual average true cost for this plan, accounting for all usage scenarios, is <span>${plan.true_cost}</span>
              </h4>
            </div>
            <div className="actions flex items-center justify-between mt-14 px-5">
              <button onClick={() => {
                window.scrollTo(0,0)
                setTrueCostStage('usage')
                setSwitchOption('summary')}
              } className="w-full p-4 bg-lumos-blue border border-lumos-blue text-white text-xl rounded flex justify-center items-center">
                Return To Plan Summary
              </button>
            </div>
          </div>}
       </div>}
    </div>
  )
}

export default Plan