import axios from 'axios'
import classNames from 'classnames'
import Navigation from '../Navigation'
import info from '../../assets/icons/info.svg'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom"


function AboutYouLocation(props){

  const [state, setState] = useState({})
  const [stage, setStage] = useState("location")
  const [errors, setErrors] = useState({})
  const [warnings, setWarnings] = useState([])
  const [stateAvailability, setStateAvailability] = useState('supported')
  const [zipcode, setZipcode] = useState(null)
  const [counties, setCounties] = useState([])
  const [location, setLocation] = useState(null)
  const [searchParams] = useSearchParams()

  const unsupportedStates = ["CA", "CO", "CT", "DC", "ID", "KY", "ME", "MD", "MA", "MN", "NV", "NJ", "NM", "NY", "PA", "RI", "VT", "WA"]

  const supportedStates = ["TX", "FL"]

  useEffect(() => {
    const appState = decode(searchParams.get('state'))
    if(appState === null){
      setState({})
    }else{
      setState(appState)
    }
    window.scrollTo(0, 0)
    if(appState && appState.location){
      updateRoute(appState)
    }
  }, [])

  function updateRoute(appState){
      setLocation(appState.location)
  }
  
  let navigate = useNavigate()

  function encode(payload){
    return encodeURIComponent(JSON.stringify(payload))
  }

  function decode(payload){
    return JSON.parse(payload)
  }

  function nextRoute(navigate){
    if(location !== null && location){
      const state = getState()
      const encodedState = encode(state)
      navigate('/about/info?state='+encodedState)
    }else{
      setErrors({
        location: [
          'Please provide a valid zipcode'
        ]
      })
    }
  }

  function getState(){
    const updatedState = state

    updatedState.location = location

    return updatedState
  }

  async function searchZipcode(e){
    setErrors({})
    const { value, maxLength } = e.target
    const updatedZipcode = value.toString().slice(0, 5)
    setZipcode(updatedZipcode)
    if(value.length >= 5){
      const response = await axios.get(process.env.REACT_APP_CMS_API_URL+'/counties/by/zip/'+updatedZipcode, {
        params: {
           apikey: process.env.REACT_APP_CMS_API_KEY
        }
      })
      setCounties(response.data.counties)
    }else{
      setCounties([])
    }
  }

  function getStateAvailabilityType(state){
    if(unsupportedStates.includes(state)){
      return 'unsupported'
    }
    if(supportedStates.includes(state)){
      return 'supported'
    }
    return 'partial'
  }

  function selectLocation(value){
    const updatedStateAvailability = getStateAvailabilityType(value.state)
    setStateAvailability(updatedStateAvailability)
    setLocation(value)
    setCounties([])
  }

  return (
    <div className="main hidden md:block form max-w-2xl mx-auto my-14">
      <Navigation/>
      <div className="step px-10 max-w-2xl mx-auto">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">Let’s get started, where do you live?</h2>
          <p className="text-lg mt-2">We need this information to pull plans you’re eligible for  from ACA</p>
        </div>
        <div className="inputs px-16 mt-10">
          <label htmlFor="zipcode" className="font-semibold text-base">Zipcode</label>
          {location === null && <div className="input flex mt-2">
            <input maxLength="5" onChange={searchZipcode} value={zipcode} type="number" placeholder="Enter your zipcode" className="zipcode w-full border border-lumos-blue block py-3 px-3 rounded-lg"/>
          </div>}
          {location && location.name && <div className="selected mt-2 flex justify-between items-center w-full border border-lumos-blue block py-3 px-3 rounded-lg">
              <span>{location.name}, {location.zipcode} {location.state}</span>
              <span onClick={() => {
                setStateAvailability('supported')
                setLocation(null)
              }} className="cursor-pointer text-lg">&times;</span>
            </div>}
          {errors && errors.location && errors.location.map((error, index) =>
            <span key={'errors-location-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
          )}
        </div>
        <div className="results px-16 mt-4">
          {counties.map((county, index) => <div onClick={() => selectLocation(county)} key={"counties-results-"+index} className="result border border-black p-4 rounded cursor-pointer hover:bg-lumos-blue hover:text-white mt-2">
                <span>{county.name}, {county.zipcode} {county.state}</span>
            </div>)}
        </div>
        <div className="warnings mt-10 text-center">
          {stateAvailability === "unsupported" && <span className="text-orange-500 text-xl mt-2 block ml-1">
            Your state operates its own state-based healthcare exchange. We are working on being able to serve users in this state but cannot do so yet. You can visit this <a target="_blank" className="text-blue-500" href="https://www.healthcare.gov/marketplace-in-your-state/">link</a> to shop directly on the exchange site for your state.
          </span>}
          {stateAvailability === "partial" && <span className="text-orange-500 text-xl mt-2 block ml-1">
            We cannot enroll users in your state directly but you can still use our tool to find recommended plans.
          </span>}
        </div>
        <div className="actions flex items-center justify-between mt-24">
          <button className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded invisible">
            Back
          </button>
          {stateAvailability !== 'unsupported' && <button onClick={() => nextRoute(navigate)} className="w-64 p-4 bg-lumos-blue border border-lumos-blue text-white text-3xl rounded">
              Next
            </button>}
        </div>
      </div>
    </div>
  )
}

export default AboutYouLocation