import {useState} from 'react'

function CoverageDetails({plan}){
  const [expandables, setExpandables] = useState({
    'doctors': false,
    'drugs': false
  })

  function handleExpandables(expandable, value){
    let updatedExpandables = {...expandables}
    updatedExpandables[expandable] = value
    setExpandables(updatedExpandables)
  }

  return (
    <div className="availability">
      {plan.provider_coverage && plan.provider_coverage.length > 0 && <div className="row border border-t-0 px-2 py-4 flex grid grid-cols-2">
        <div className="value font-semibold">
         Doctors: {plan.provider_coverage.filter(item => item.is_covered).length} out of {plan.provider_coverage.length} covered
        </div>
        <div className="expandable text-right">
          <button onClick={() => handleExpandables('doctors', !expandables.doctors)}>
           {!expandables.doctors && <span>Expand <span>&#8595;</span></span>}
           {expandables.doctors && <span>Collapse <span>&#8593;</span></span>}
          </button>
        </div>
     </div>}
    {expandables.doctors && plan.provider_coverage && plan.provider_coverage.length > 0 && plan.provider_coverage.map((provider, index) => <div key={"providers-"+index} className="row border border-t-0 px-2 py-4 flex grid grid-cols-2">
      <h1 className="text-lumos-gray-dark">{provider.name}</h1> 
      {provider.is_covered && <span className="text-green-600 ml-4">Takes this plan</span>}
      {!provider.is_covered && <span className="text-red-600 ml-4">Not covered</span>}
    </div>)}
    {plan.drug_coverage && plan.drug_coverage.length > 0 && <div className="row border border-t-0 px-2 py-4 flex grid grid-cols-2">
        <div className="value font-semibold">
         Drugs: {plan.drug_coverage.filter(item => item.is_covered).length} out of {plan.drug_coverage.length} covered
        </div>
        <div className="expandable text-right">
          <button onClick={() => handleExpandables('drugs', !expandables.drugs)}>
           {!expandables.drugs && <span>Expand <span>&#8595;</span></span>}
           {expandables.drugs && <span>Collapse <span>&#8593;</span></span>}
          </button>
        </div>
     </div>}
    {expandables.drugs && plan.drug_coverage && plan.drug_coverage.length > 0 && plan.drug_coverage.map((drug, index) => <div key={"drugs-"+index} className="row border border-t-0 px-2 py-4 flex grid grid-cols-2">
        <h1 className="text-lumos-gray-dark">{drug.name}</h1> 
        {drug.is_covered && <span className="text-green-600 ml-4">Covered</span>}
        {!drug.is_covered && <span className="text-red-600 ml-4">Not covered</span>}
      </div>)}
    </div>
  )
}

export default CoverageDetails
