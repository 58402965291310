import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LinearScale,
  LineElement,
  Tooltip,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
);

function CohortUsageChart({plan}){

  const options = {
    // responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          // useLineStyle: true,
          usePointStyle: true,
          pointStyle: 'line',
          // boxWidth: 200,
          // boxHeigth: 0,
        },
        display: false
      },
      tooltip: {
      },
      datalabels: {
        display: false,
      }
    },
    scales: {
      // to remove the labels
      x: {
        ticks: {
          font: {
            size: 12,
            weight: 'normal',
            family: "Merriweather"
          },
          display: true,
        },

        // to remove the x-axis grid
        grid: {
          drawBorder: true,
          display: false,
        },
      },
      // to remove the y-axis labels
      y: {
        ticks: {
          callback: function(value, index, values) {
              return '$' + numberWithCommas(value.toFixed(0));
          },
          font: {
            size: 12,
            weight: 'normal',
            family: "Merriweather"
          },
          display: true,
        },
        // to remove the y-axis grid
        grid: {
          drawBorder: true,
          display: false,
          lines: {
            drawTicks: false
          }
        },
      },
    }
  }

  const labels = ['Premiums Only', 'Lowest Usage', 'Low Usage', 'Median Usage', 'High Usage', 'Highest Usage', 'Max Cost']

  const lines = plan.consequence_graph.map(item => Math.round((item.true_cost + Number.EPSILON) * 100) / 100)

  // const lines = [].concat(rawLines)
  function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const data = {
    labels,
    datasets: [
      {
        data: lines,
        borderColor: '#000',
        backgroundColor: ['#000'],
      },
    ],
  };


  return (
    <div className="cohort-usage-chart">
      <div className="graph mt-10 px-5">
      <span id="chartjs-tooltip"></span>
        <Line className="mt-5" options={options} data={data}></Line>
      </div>
    </div>
  )
}

export default CohortUsageChart