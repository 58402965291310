import React from 'react'
import classNames from 'classnames'
import ReactStars from 'react-stars'

function PlanDetails({plan, rating, className = "", subsidy}){

  function getGovTaxCredit(premium, subsidy){
    return commas(Math.round((Math.max(0, premium - subsidy) + Number.EPSILON) * 100) / 100)
  }

  function getLogo(name){
    let url = "/logos/"+name
    return url+".png"
  }

  var metalClass = function(option, custom = ''){
    return classNames('metal-level flex items-center mx-8' + custom, {
      'text-red-600': option === "Catastrophic",
      'text-bronze': option === "Bronze",
      'text-silver': option === "Silver",
      'text-gold': option === "Gold",
      'text-platinum': option === "Platinum",
    })
  }

  function commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className={`info ${className}`}>
      <div className="header">
        <div className="provider border-lumos-gray border px-2 py-4 flex justify-between items-center">
          <div className="title flex items-center">
            <img className="w-24 h-auto inline text-sm" src={getLogo(plan.issuer)} alt={plan.issuer}/>
            <h2 className="ml-2 inline text-sm pr-8">{plan.plan_name}</h2>
          </div>
          <div className="rating flex items-center">
            {rating.global_rating > 0 && <ReactStars
                edit={false}
                value={rating.global_rating}
                count={5}
                size={24}
                color2={'#ffd700'}/>}
            {rating.global_rating === 0 && <span>Rating is Not Available</span>}
            <div className={metalClass(plan.metal_level)}>
              <span className="text-xs mr-3">&#11044;</span> 
              <span className="text-lg">{plan.metal_level}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="provider-details grid grid-cols-3">
        <div className="detail px-2 py-4 border border-lumos-gray border-t-0">
          <h3 className="text-lumos-blue text-base">True Cost</h3>
        </div>
        <div className="detail px-2 py-4 border border-lumos-gray border-t-0 border-l-0">
          <h3 className="text-lumos-blue text-base">Coverage</h3>
        </div>
        <div className="detail px-2 py-4 border border-lumos-gray border-t-0 border-l-0">
          <h3 className="text-lumos-blue text-base">Other Details</h3>
        </div>
      </div>
      <div className="provider-details grid grid-cols-3">
        <div className="detail px-2 py-4 border border-lumos-gray border-t-0">
          <div className="row grid grid-cols-2 items-center">
            <h4 className="text-lumos-gray-dark text-sm">Premiums w/ Gov’t Tax Credits</h4>
            <div className="premiums text-center">
              <h2 className="text-base">${getGovTaxCredit(plan.premium, subsidy)}/mo * 12</h2>
              <span className="text-xs text-red-600 line-through">(${commas(plan.premium)}/mo without tax credits)</span>
            </div>
          </div>
          <div className="row grid grid-cols-2 items-center mt-5">
            <h4 className="text-lumos-gray-dark text-sm">Annual Estimated OOP Expenses</h4>
            <div className="premiums text-center border-b pb-2 border-black">
              <h2 className="text-base">+ ${commas(plan.oop_mean)}</h2>
            </div>
          </div>
          <div className="row grid grid-cols-2 items-center mt-5">
            <h4 className="text-lumos-gray-dark text-sm">Annual True Cost</h4>
            <div className="premiums text-center">
              <h2 className="text-base">${commas(plan.true_cost)}</h2>
            </div>
          </div>
        </div>
        <div className="detail px-2 py-4 border border-lumos-gray border-t-0 border-l-0">
          <div className="row grid grid-cols-2 items-center">
            <h4 className="text-lumos-gray-dark text-sm">My Doctors Covered</h4>
            <div className="premiums text-center">
              <h2 className="text-base">{plan.provider_coverage.filter(item => item.is_covered).length} out of {plan.provider_coverage.length} doctors</h2>
            </div>
          </div>
          <div className="row grid grid-cols-2 items-center mt-5">
            <h4 className="text-lumos-gray-dark text-sm">My Drugs Covered</h4>
            <div className="premiums text-center">
              <h2 className="text-base">{plan.drug_coverage.filter(item => item.is_covered).length} out of {plan.drug_coverage.length} prescriptions</h2>
            </div>
          </div>
          <div className="row grid grid-cols-2 items-center mt-5">
            <h4 className="text-lumos-gray-dark text-sm">Referral Required</h4>
            <div className="premiums text-center">
              <h2 className="text-base">{plan.referral_desc}</h2>
            </div>
          </div>
        </div>
        <div className="detail px-2 py-4 border border-lumos-gray border-t-0 border-l-0">
          <div className="row grid grid-cols-2 items-center">
            <h4 className="text-lumos-gray-dark text-sm">{Object.keys(plan.deductible)[0]}</h4>
            <div className="premiums text-center">
              <h2 className="text-base">${commas(Object.values(plan.deductible)[0])}</h2>
            </div>
          </div>
          {Object.keys(plan.deductible)[1] && <div className="row grid grid-cols-2 items-center">
            <h4 className="text-lumos-gray-dark text-sm mt-3">{Object.keys(plan.deductible)[1]}</h4>
            <div className="premiums text-center">
              <h2 className="text-base">${commas(Object.values(plan.deductible)[1])}</h2>
            </div>
          </div>}
          <div className="row grid grid-cols-2 items-center mt-3">
            <h4 className="text-lumos-gray-dark text-sm">Out-of-Pocket Maximum</h4>
            <div className="premiums text-center">
              <h2 className="text-base">${commas(plan.oop_max)}</h2>
            </div>
          </div>
          <div className="row grid grid-cols-2 items-center mt-3">
            <h4 className="text-lumos-gray-dark text-sm">PCP / Specialist Visit Fee</h4>
            <div className="premiums text-center">
              <h2 className="text-base">{plan.doctor_visit_desc} </h2>
            </div>
          </div>
          <div className="row grid grid-cols-2 items-center mt-3">
            <h4 className="text-lumos-gray-dark text-sm">Generic Drugs</h4>
            <div className="premiums text-center">
              <h2 className="text-base">{plan.generic_drug_desc} </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlanDetails