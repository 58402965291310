import _ from 'lodash'
import axios from 'axios'
import Select from 'react-select'
import Navigation from '../Navigation'
import info from '../../assets/icons/info.svg'
import formula from '../../assets/images/formula.png'
import HealthStatusChart from '../sections/HealthStatusChart'
import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"

const options = [
  { value: 1, label: '1-Excellent' },
  { value: 2, label: '2-Very Good' },
  { value: 3, label: '3-Good' },
  { value: 4, label: '4-Fair' },
  { value: 5, label: '5-Poor' }
]

function Coverage(props){

  const [searchParams] = useSearchParams()
  const [stage, setStage] = useState('formula')
  const [state, setState] = useState({})
  const [healthStatus, setHealthStatus] = useState(1)
  const [healthStatusChartPayload, setHealthStatusChartPayload] = useState({})
  let navigate = useNavigate()

  useEffect(() => {
    const appState = decode(searchParams.get('state'))
    setState(appState)
    if(stateHasLocation(appState) && 
        stateHasInfo(appState) && 
        stateHasSavings(appState) &&
        stateHasProviders(appState) &&
        stateHasDrugs(appState)){
      window.scrollTo(0, 0)
      updateRoute(appState)
    }else{
      previousRoute(navigate)
    }
  }, [])

  function updateRoute(appState){
    if(appState.primary){
      if(appState.primary.health_status !== undefined){
        setStage('usage')
        setHealthStatus(appState.primary.health_status)
      }
    }
    axios.post(process.env.REACT_APP_API_URL+'/cohortselection', {
      "age": appState.primary.age,
      "sex": appState.primary.gender
    }).then(chartResponse => {
      setHealthStatusChartPayload(chartResponse.data)
    })
  }

  function stateHasLocation(appState){
    return appState && appState.location !== undefined && appState.location !== null
  }

  function stateHasInfo(appState){
    return appState && appState.primary &&
             appState.primary.age &&
             appState.primary.gender
  }

  function stateHasSavings(appState){
    return appState && appState.household_people &&
               appState.household_income
  }

  function stateHasProviders(appState){
    return appState && appState.providers &&
               appState.only_preferred_providers !== undefined
  }

  function stateHasDrugs(appState){
    return appState && appState.drugs &&
               appState.only_preferred_drugs !== undefined
  }

  function getState(){
    const updatedState = state
    updatedState.primary.health_status = healthStatus
    return updatedState
  }
  
  function previousRoute(navigate){
    //add state
    const updatedState = getState()
    const encodedState = encode(updatedState)
    navigate('/coverage/drugs?state='+encodedState)
  }

  function nextRoute(navigate){
    const state = getState()
    const encodedState = encode(state)
    navigate('/other?state='+encodedState)
  }

  function decode(payload){
    return JSON.parse(payload)
  }

  function encode(payload){
    return encodeURIComponent(JSON.stringify(payload))
  }

  function getGender(appState){
    switch(appState.primary.gender) {
      case 'M':
        return "Male"
        break;
      case 'F':
        return "Female"
        break;
      default:
    }
  }

  function getDefaultHealthStatusValue(){
      return _.find(options, function(o) { return o.value === healthStatus; });
  }

  return (
    <div className="main hidden md:block form max-w-2xl mx-auto my-14">
      <Navigation/>
      {stage === 'formula' && <div className="step">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">Great! Now, let’s estimate your healthcare usage needs so we can calculate your <span className="underline">true cost</span> under each plan</h2>
        </div>
        <div className="flex items-center mt-14">
          <img className="w-full" src={formula} alt="Formula"/>
        </div>
        <div className="title info mt-14 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">While your out of pocket expenses are unknown ahead of time, we can still estimate and get a good approximation</h2>
        </div>
        <div className="actions flex items-center justify-between mt-20">
          <button onClick={() => previousRoute(navigate)} className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded">
            Back
          </button>
          <button onClick={() => {
              window.scrollTo(0, 0)
              setStage('usage')
            }} className="w-64 p-4 bg-lumos-blue border border-lumos-blue text-white text-3xl rounded">
            Let's Estimate
          </button>
        </div>
      </div>}
      {stage === 'usage' && <div className="step px-5">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">We can estimate your out-of-pocket expenses based on the healthcare usage of people similar to you</h2>
          <p className="text-lg mt-5">Answer questions below to identify people similar to you. We will use your responses to pull the set of people in our dataset who answered similarly and use their healthcare usage as an approximation to estimate your true cost</p>
        </div>
        <div className="data">
          <div className="flex mt-10 justify-center">
            <div className="age text-center">
              <p className="text-lg font-semibold text-center">Age</p>
              <div className="border border-lumos-blue px-14 py-3  rounded-lg mt-2 bg-lumos-back">{state.primary.age}</div>
            </div>
            <div className="age text-center ml-14">
              <p className="text-lg font-semibold text-center">Gender</p>
              <div className="border border-lumos-blue px-14 py-3  rounded-lg mt-2 bg-lumos-back">{getGender(state)}</div>
            </div>
          </div>
        </div>
        <div className="text-center mt-10">
          <p className="font-semibold inline text-lg text-center">On a scale of 1-5, with 1 being Excellent and 5 being Poor, select the number that best represents your current health</p>
        </div>
        <div className="input px-16 mt-6 mb-10">
            <Select
              defaultValue={getDefaultHealthStatusValue()}
              onChange={(option) => setHealthStatus(option.value)}
              className="w-full mt-2"
              options={options}
            />
            <p onClick={() => {
              window.scrollTo(0,10)
              setStage('status')
            }} className="underline cursor-pointer text-lumos-blue float-right mt-2">Help me select</p>
        </div>
        <div className="actions flex items-center justify-between mt-24">
          <button onClick={() => {
              window.scrollTo(0, 0)
              setStage('formula')
            }} className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded">
            Back
          </button>
          <button onClick={() => nextRoute(navigate)} className="w-64 p-4 bg-lumos-blue border border-lumos-blue text-white text-3xl rounded font-semibold">
            Next
          </button>
        </div>
      </div>}
      {stage === 'status' && <div className="step absolute top-12 pb-20 bg-white hidden md:block form max-w-2xl mx-auto my-14">
        <span className="text-3xl text-lumos-blue cursor-pointer float-right" onClick={() => {
          // window.scrollTo(0,0)
          setStage('usage')
        }}>&times;</span>
        <div className="title info mt-10">
          <h2 className="text-xl text-lumos-blue font-semibold">Select Health Status</h2>
          <div className="banner border border-black mt-6 bg-lumos-light p-5">
            <span>
              Use the chart below to select the health status that best reflects your health status and usage patterns. Hover over the bars to see the median usage of various health services for people in each age-gender-status group
            </span>
          </div>
          <h2 className="text-xl text-lumos-blue font-semibold mt-8">% of {getGender(state)}s Aged {healthStatusChartPayload.age_bucket[0]} to {healthStatusChartPayload.age_bucket[1]} Selecting Each Health Status</h2>
          {healthStatusChartPayload.age && <HealthStatusChart payload={healthStatusChartPayload} />}
          <h2 className="text-xl text-lumos-blue font-semibold mt-8">Based on your own knowledge of your health and your usage patterns, select the status that would best represent your typical healthcare consumption</h2>
          <Select
            defaultValue={getDefaultHealthStatusValue()}
            onChange={(option) => {
              setHealthStatus(option.value)
            }}
            className="w-full mt-8"
            options={options}
            />
            <button onClick={() => {
              setStage('usage')
            }} className="w-full mt-8 p-4 bg-lumos-blue border border-lumos-blue text-white text-xl rounded">
            Update My Selection
          </button>
        </div>
      </div>}
  </div>
  )
}

export default Coverage
