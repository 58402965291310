import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LinearScale,
  LineElement,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend
);

function CohortUsageChart({plans}){

  const options = {
    // responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          // useLineStyle: true,
          usePointStyle: true,
          pointStyle: 'line',
          // boxWidth: 200,
          // boxHeigth: 0,
        },
        position: 'chartArea',
        align: 'start'
      },

      tooltip: {
      },
      datalabels: {
        display: false,
      }
    },
    scales: {
      // to remove the labels
      x: {
        ticks: {
          font: {
            size: 12,
            weight: 'normal',
            family: "Merriweather"
          },
          display: true,
        },

        // to remove the x-axis grid
        grid: {
          drawBorder: true,
          display: false,
        },
      },
      // to remove the y-axis labels
      y: {
        ticks: {
          callback: function(value, index, values) {
              return '$' + commas(value.toFixed(0));
          },
          font: {
            size: 12,
            weight: 'normal',
            family: "Merriweather"
          },
          display: true,
        },
        // to remove the y-axis grid
        grid: {
          drawBorder: true,
          display: false,
          lines: {
            drawTicks: false
          }
        },
      },
    }
  }

  const labels = ['Premiums Only', 'Lowest Usage', 'Low Usage', 'Median Usage', 'High Usage', 'Highest Usage', 'Max Cost']

  const datasets = []

  const colors = [
    "#AC0202",
    "#1F7401",
    "#054075",
    "#F8A403",
    "#000000",
    "#DC9595"
  ]

  for (let i = 0; i < plans.length; i++) {
    let plan = plans[i]
    let lines = plan.consequence_graph.map(item => Math.round((item.true_cost + Number.EPSILON) * 100) / 100)
    datasets.push({
      label: plan.issuer + " - " + plan.plan_name,
      data: lines,
      borderColor: colors[i],
      backgroundColor: [colors[i]],
    })
  }

  function commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const data = {
    labels,
    datasets
  };


  return (
    <div className="cohort-usage-chart">
      <div className="graph mt-10 px-5">
      <span id="chartjs-tooltip"></span>
       <Line className="mt-5" options={options} data={data}></Line>
      </div>
    </div>
  )
}

export default CohortUsageChart