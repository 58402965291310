import Select from 'react-select'
import axios from 'axios'
import Navigation from '../Navigation'
import PlanDetails from '../PlanDetails'
import CoverageDetails from '../CoverageDetails'
import Contact from '../Contact'
import Plan from '../Plan'
import PlanComparison from '../sections/PlanComparison'
import info from '../../assets/icons/info.svg'
import arrowDown from '../../assets/icons/arrow-down.svg'
import React, { useEffect, useState, useRef } from 'react'
import { NumericFormat } from "react-number-format"
import formula from '../../assets/images/formula.png'
import { useSearchParams, useNavigate } from "react-router-dom"
import classNames from 'classnames'


function Results(props){

  const [searchParams] = useSearchParams()
  const [state, setState] = useState({})
  const [loading, setLoading] = useState(true)
  const [stage, setStage] = useState('plans')
  const [progress, setProgress] = useState(0)
  const [errors, setErrors] = useState({})
  const [plans, setPlans] = useState([])
  const [data, setData] = useState({})
  const [rainyDayFund, setRainyDayFund] = useState("")
  const [selectedOption, setSelectedOption] = useState("recommended")
  const [selectedTab, setSelectedTab] = useState(0)
  const [costTolerance, setCostTolerance] = useState("")
  let navigate = useNavigate()
  const [selectedPlanRating, setSelectedPlanRating] = useState({})
  const timerRef = useRef(null);
  const progressRef = useRef(null);
  const comparisonSectionRef = useRef(null)

  const executeScroll = () => comparisonSectionRef.current.scrollIntoView()
  const supportedStates = ["TX", "FL"] 

  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    const appState = decode(searchParams.get('state'))
    setState(appState)
    loadingAnimation()
    if(stateHasLocation(appState) && 
        stateHasInfo(appState) && 
        stateHasSavings(appState) &&
        stateHasProviders(appState) &&
        stateHasDrugs(appState)){
      window.scrollTo(0, 0)
      setTimeout(() => updateRoute(appState), 300) //remove
      // updateRoute(appState)
    }else{
      previousRoute(navigate)
    }
  }, [])

  function loadingAnimation(){
    timerRef.current = setTimeout(() => {
      if(!loading){
        clearTimeout(timerRef.current)
      }
      if(loading && progressRef.current < 100){
        let updatedProgress = progressRef.current + 25
        setProgress(updatedProgress)
        loadingAnimation()
      }else{
        if(loading && progressRef.current >= 100){
          setProgress(0)
          loadingAnimation()
        }
      }
    }, 1000)
  }

  async function updateRoute(appState){
    const payload = {
      "location": appState.location,
      "primary": {
        "age": appState.primary.age,
        "sex": getGender(appState.primary.gender),
        "tobacco": appState.primary.uses_tobacco,
        "pregnant": appState.primary.is_pregnant,
        "has_mec": false,
        "health_status": appState.primary.health_status
      },
      "household_size": appState.household_people,
      "household_income": appState.household_income,
      "only_preferred_providers": appState.only_preferred_providers,
      "only_preferred_drugs": appState.only_preferred_drugs,
      "rainy_day_fund": appState.rainy_day_fund,
      "cost_tolerance": appState.cost_tolerance,
      "dummy_response_ind": 0
    }

    if(appState.providers && appState.providers.length > 0){
      payload.providers = appState.providers.map(provider => {
        return {
          npi: provider.provider.npi,
          name: provider.provider.name
        }
      })
    }

    if(appState.drugs && appState.drugs.length > 0){
      payload.drugs = appState.drugs.map(drug => {
        return {
          name: drug.name,
          rxcui: drug.rxcui,
          dosage: drug.dosage,
          supply: parseInt(drug.supply)
        }
      })
    }

    axios.post(process.env.REACT_APP_API_URL+"/recommend_plans", payload).then(response => {
      setPlans(response.data.plans)
      let data = {...response.data}
      delete data.plans
      setData(data)
      axios.get(process.env.REACT_APP_CMS_API_URL+'/plans/'+response.data.plans[0].id+'/quality-ratings', {
        params: {
           apikey: process.env.REACT_APP_CMS_API_KEY
        }
      }).then(ratingResponse => {
        setLoading(false)
        setSelectedPlanRating(ratingResponse.data)
      })
    })
  }

  const checkImage = path =>
    new Promise(resolve => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = path;
    });

  function getLogo(name){
    let url = "/logos/"+name
    return url+".png"
  }

  function stateHasLocation(appState){
    return appState && appState.location !== undefined && appState.location !== null
  }

  function stateHasInfo(appState){
    return appState && appState.primary &&
             appState.primary.age &&
             appState.primary.gender
  }

  function stateHasSavings(appState){
    return appState && appState.household_people &&
               appState.household_income
  }

  function stateHasProviders(appState){
    return appState && appState.providers &&
               appState.only_preferred_providers !== undefined
  }

  function stateHasDrugs(appState){
    return appState && appState.drugs &&
               appState.only_preferred_drugs !== undefined
  }

  function getGender(gender){
    switch(gender) {
      case 'M':
        return "Male"
        break;
      case 'F':
        return "Female"
        break;
      default:
    }
  }
  
  function getState(){
    const updatedState = state

    if(rainyDayFund) updatedState.rainy_day_fund = rainyDayFund.replace(/\,/g,'')
    if(costTolerance) updatedState.cost_tolerance = costTolerance.replace(/\,/g,'')

    return updatedState
  }
  
  function previousRoute(navigate){
    const updatedState = getState()
    const encodedState = encode(updatedState)
    navigate('/other?state='+encodedState)
  }

  function nextRoute(navigate){
    // const state = getState()
    // const encodedState = encode(state)
    // navigate('/other/save?state='+encodedState)
  }

  function decode(payload){
    return JSON.parse(payload)
  }

  function encode(payload){
    return encodeURIComponent(JSON.stringify(payload))
  }

  async function handleSelectedTab(index){
    setSelectedTab(index)
    const selectedPlan = plans[index]
    const response = await axios.get(process.env.REACT_APP_CMS_API_URL+'/plans/'+selectedPlan.plan.plan_id+'/quality-ratings', {
      params: {
         apikey: process.env.REACT_APP_CMS_API_KEY
      }
    })
    setSelectedPlanRating(response.data)
  }

  var optionClass = function(option, custom = ''){
    return classNames('cursor-pointer px-5 rounded py-5 ' + custom, {
      'border': selectedOption !== option,
      'bg-lumos-blue text-white': selectedOption === option
    })
  }

  var selectedPlanClass = function(index, custom = ''){
    return classNames('provider flex items-center cursor-pointer px-2 py-4 border border-r-0 border-b-0 last:border-r' + custom, {
      'bg-lumos-gray hover:bg-lumos-teal': selectedTab !== index,
    })
  }

  function truncate(str){
    if(plans.length > 3){
      return (str.length > 23) ? str.slice(0, 23-1) + '...' : str;
    }else if(plans.length > 2){
      return (str.length > 38) ? str.slice(0, 38-1) + '...' : str;
    }else{
      return str;
    }
  }

  function commas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="main hidden md:block form max-w-5xl mx-auto my-10">
      {loading && <div className="title info mt-36">
        <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">Processing your data...</h2>
        {progress >= 25 && <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">Pulling plans from ACA...</h2>}
        {progress >= 50 && <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">Calculating true cost of each plan...</h2>}
        {progress >= 75 && <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">Finding the plans that are right for you...</h2>}
      </div>}
      {!loading && stage === 'details' && <div className="step">
        <Plan subsidy={data.subsidy} cohortUsage={data.cohort_usage} locationState={state.location.state} plan={plans[selectedTab]} setStage={setStage} rating={selectedPlanRating}/>
      </div>}
      {!loading && stage === 'enroll' && <div className="step">
        <div className="bar w-full">
          <button onClick={() => setStage('plans')} className="text-lg text-lumos-blue">&#x2190; Back</button>
        </div>
        <div className="title info mt-10">
          <h2 className="text-3xl text-lumos-blue text-center font-semibold">Congrats on choosing a plan to enroll in!</h2>
          <p className="text-lg mt-4 text-center text-lumos-blue">Submit the information below and a broker will contact you to help you enroll in this plan. You will only be contacted by a broker on our team at your preferred method and time of communication</p>
        </div>
        <h2 className="text-lg text-lumos-blue font-semibold mt-12">Chosen Plan</h2>
        <PlanDetails subsidy={data.subsidy} className="mt-2" rating={selectedPlanRating} plan={plans[selectedTab]}/>
        <Contact plan={plans[selectedTab]} appState={state} setStage={setStage}/>
      </div>}
      {!loading && stage === 'plans' && <div className="step">
        <div className="plans-switch info mt-10 text-center">
          <div className="switch flex grid grid-cols-2 mx-10">
            <button onClick={() => setSelectedOption('recommended')} className={optionClass('recommended')}>
              Recommended Plans
            </button>
            <button onClick={() => setSelectedOption('recommended')} className={optionClass('all', 'text-lumos-inactive border-lumos-blue')}>
              See All Plans (Feature coming soon)
            </button>
          </div>
        </div>
        <div className="title info mt-10">
          <h2 className="text-3xl text-lumos-blue text-center font-semibold">{data.message1}</h2>
        </div>
        <div className="results mt-10">
          <table className="border-collapse border border-slate-400 w-full">
            <tbody>
              <tr className="border-0 text-center font-semibold">
                <td className="border border-white border-r-slate-300 border-b-slate-300"></td>
                <td className="border border-slate-300 py-3">Annual True Cost</td>
                <td className="border border-slate-300 py-3">Why it may be right for me?</td>
              </tr>
              {plans.length > 0 && plans.map((plan, index) => <tr key={"plan-"+index}>
                <td className="border border-slate-300 border-collapse w-96 py-8 px-4">
                  <div className="grid grid-cols-6 items-center">
                    <div className="image text-center col-span-2">
                      <img className="inline w-20" src={getLogo(plan.issuer)} alt={plan.issuer}/> 
                    </div>
                    <h2 className="font-semibold inline ml-8 col-span-4">{plan.plan_name}</h2>
                  </div>
                </td>
                <td className="border p-4 border-slate-300 text-center">
                  <p>${commas(plan.true_cost)}</p>
                </td>
                <td className="border p-4 border-slate-300 text-center">
                  {plan.tags.map((tag, index) => 
                    <div className="tooltip-container ml-8 block">
                      <div className="tooltip">
                        <span className="px-8 py-2 bg-lumos-tags underline border border-black rounded-lg w-full mb-4" disabled>
                          {tag.label}
                        </span>
                        <span className="tooltip-drop tooltip-bottom">
                          {tag.desc}
                        </span>
                      </div>
                  </div>
                  )}
                </td>
              </tr>)}
            </tbody>
          </table>
        </div>    
        <div className="title info mt-10">
          <h2 className="text-3xl text-lumos-blue text-center font-semibold">{data.message2}</h2>
        </div>
        <div className="details mt-10">
          <div className="grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5"></div>{/*load otherwise purged tailwind's grid cols*/}
          <div className={"grid grid-cols-"+plans.length}>
            {plans.length > 0 && plans.map((plan, index) => <div onClick={() => handleSelectedTab(index)} key={"plan-tab-"+index} className={selectedPlanClass(index)}>
              <div className="w-24 text-center">
                <img className="w-10 h-auto inline text-sm" src={getLogo(plan.issuer)} alt={plan.issuer}/>
              </div>
              <h2 className="ml-2 inline text-sm">{truncate(plan.plan_name)}</h2>
            </div>)}
          </div>
          <PlanDetails subsidy={data.subsidy} rating={selectedPlanRating} plan={plans[selectedTab]}/>
          <CoverageDetails plan={plans[selectedTab]}/>
          <div className="row border border-t-0 px-4 py-4 flex justify-end bg-lumos-teal">
            <button onClick={() => {
              window.scrollTo(0,0)
              setStage('details')
            }} className="px-8 py-2 bg-lumos-blue text-white rounded-lg">
              View Details
            </button>
            {supportedStates.includes(state.location.state) && <button onClick={() => {
                window.scrollTo(0,0)
                setStage('enroll')
              }} className="px-8 py-2 bg-green-700 ml-8 text-white rounded-lg">
                Enroll In Plan
              </button>}
            {!supportedStates.includes(state.location.state) && 
              <div className="tooltip-container ml-8">
                  <div className="tooltip">
                      <button className="px-8 py-2 bg-gray-400 text-gray-200 rounded-lg cursor-not-allowed " disabled>
                        Enroll In Plan
                      </button>
                    <span className="tooltip-drop tooltip-top">
                      We are unable to process enrollments in your state. If you’d like to enroll in this plan, you can do so by enrolling in the plan directly through www.healthcare.gov
                    </span>
                  </div>
              </div>}
          </div>
        </div>
        <div className="actions flex items-center justify-between mt-14">
          <button onClick={() => {
            executeScroll()
          }} className="w-full p-4 bg-lumos-blue border border-lumos-blue text-white text-3xl rounded flex justify-center items-center">
            See More Detailed Comparison of Plans <img className="ml-4 w-6" src={arrowDown} alt=""/>
          </button>
        </div>
      <div className="step" ref={comparisonSectionRef}>
        {plans.length > 0 && <PlanComparison subsidy={data.subsidy} cohortUsage={data.cohort_usage} plans={plans} setStage={setStage} />}
      </div>
      </div>}
  </div>
  )
}

export default Results
