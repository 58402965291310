  function ComparisonTableCoverage({data, listsKey}){

  return (
    <div className="flex flex-col mt-5">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-center">
              <thead className="border-b">
                <tr>
                  <th
                    scope="col"
                    className="text-sm font-medium text-white px-6 py-4"
                  >
                  </th>
                  {data.headers.map((header, index) => <th key={listsKey+"table-titles-"+index}
                    scope="col"
                    className={(index === 0) ? "text-xs font-medium border-r border-t px-6 py-4 border-l align-top" : "text-xs font-medium border-r border-t px-6 py-4 align-top"}
                  >
                    <div className="image h-12 flex items-center">
                      <img className="w-24 mx-auto w-full" src={header.logo} alt={header.alt}/>
                    </div>
                    <h2 className="mt-2">{header.label}</h2>
                  </th>)}
                </tr>
              </thead>
              <tbody>
                {data.content.map((content, index) => <tr key={listsKey+"table-content-"+index} className="bg-white border">
                    <td className="px-6 py-4 text-left whitespace-nowrap text-sm font-semibold text-gray-900 border-r">
                      {content.title}
                    </td>
                    {content.data.map((item, i) => 
                      <td key={listsKey+'table-items-'+i} className="text-sm border-r px-6 py-4 whitespace-nowrap">
                        <span className={item.class}>
                          {item.label}
                        </span>
                      </td>
                    )}
                  </tr>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComparisonTableCoverage