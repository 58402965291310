import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Select from 'react-select'
import { PatternFormat } from 'react-number-format';


function Contact({plan, appState, setStage}){
  const [errors, setErrors] = useState({})
  const [isSent, setIsSent] = useState(false)
  const [form, setForm] = useState({
    email: appState.email !== undefined ? appState.email : "",
    phone: "",
    first_name: "",
    contact_method: "email",
    preferred_time: "morning",
    additional_comments: "",
  })
  const [details, setDetails] = useState({})

  const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  function submitContactForm(){

    let updatedErrors = {}

    if (!isEmail(form.email)) {
      updatedErrors.email = ["Please provide a valid email"]
    }

    if(form.first_name === undefined || form.first_name === null || form.first_name === ""){
      updatedErrors.first_name = ["A first name is required"]
    }

    if(form.contact_method === "phone" && (form.phone === null || form.phone === "" || !form.phone)){
      updatedErrors.phone = ["Please provide a phone number"]
    }

    if(form.email === null || form.email === "" || !form.email){
      updatedErrors.email = ["Please provide an email"]
    }

    if(Object.keys(updatedErrors).length > 0){
      setErrors(updatedErrors);
    }else{
      const payload = {
        "location": {
          "zipcode": appState.location.zipcode,
          "county_name": appState.location.name,
          "fips": appState.location.fips,
          "state_code": appState.location.state
        },
        "primary": {
          "age": appState.primary.age,
          "sex": appState.primary.gender,
          "tobacco": appState.primary.uses_tobacco,
          "pregnant": appState.primary.is_pregnant,
          "has_mec": false,
          "health_status": appState.primary.health_status
        },
        "household_size": appState.household_people,
        "household_income": appState.household_income,
        "only_preferred_providers": appState.only_preferred_providers,
        "only_preferred_drugs": appState.only_preferred_drugs,
        "rainy_day_fund": appState.rainy_day_fund,
        "cost_tolerance": appState.cost_tolerance,
        "chosen_plan_id": plan.plan_id,
        "contact_info": form
      }

      if(appState.providers && appState.providers.length > 0){
        payload.providers = appState.providers.map(provider => {
          return {
            provider_id: provider.provider.npi
          }
        })
      }

      if(appState.drugs && appState.drugs.length > 0){
        payload.drugs = appState.drugs.map(drug => {
          return {
            rxcui: drug.rxcui,
            dosage: drug.dosage,
            supply: parseInt(drug.supply)
          }
        })
      }

      axios.post(process.env.REACT_APP_API_URL+"/enroll", payload).then(response => {
        if(response.status === 200){
          setIsSent(true)
        }
      })
    }
  }

  function handleFormChange(field, value){
    let updatedForm = {...form}
    updatedForm[field] = value
    setForm(updatedForm)
  }

  return (
    <div className="contact-details">
      <h2 className="text-lumos-blue text-xl mt-10">Contact Information</h2>
      <hr className="border border-lumos-blue w-full mt-2"/>
      {!isSent && <div className="form grid grid-cols-2 mt-10 gap-x-10 gap-y-4">
        <div className="field">
          <label className="text-lumos-blue font-semibold">First Name</label>
          <input onChange={(e) => handleFormChange('first_name', e.target.value)} type="text" placeholder="First Name" className="mt-2 mt-2 w-full border border-lumos-blue block py-3 px-3 rounded-lg"/>
          {errors && errors.first_name && errors.first_name.map((error, index) =>
            <span key={'errors-first_name-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
          )}
        </div>
        <div className="field">
          <label className="text-lumos-blue font-semibold">Contact Preference</label>
          <select type="text" onChange={(e) => handleFormChange('contact_method', e.target.value)}  placeholder="Contact Preference" className="lumos-select mt-2 w-full block py-3 px-3 rounded-lg">
            <option value="email">Email</option>
            <option value="phone">Phone</option>
          </select>
          {errors && errors.contact_method && errors.contact_method.map((error, index) =>
            <span key={'errors-contact_method-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
          )}
        </div>
        <div className="field">
          <label className="text-lumos-blue font-semibold">Email</label>
          <input type="text" onChange={(e) => handleFormChange('email', e.target.value)} value={form.email} placeholder="Email" className="mt-2 w-full border border-lumos-blue block py-3 px-3 rounded-lg"/>
          {errors && errors.email && errors.email.map((error, index) =>
            <span key={'errors-email-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
          )}
        </div>
        <div className="field">
          <label className="text-lumos-blue font-semibold">Preferred Time</label>
          <select type="text" onChange={(e) => handleFormChange('preferred_time', e.target.value)}  placeholder="Preferred Time" className="mt-2 w-full lumos-select block py-3 px-3 rounded-lg">
            <option value="morning">Morning (9am - 12pm)</option>
            <option value="afternoon">Afternoon (12pm -3pm)</option>
            <option value="late_afternoon">Late Afternoon (3pm - 6pm)</option>
            <option value="evening">Evening (6pm - 9pm)</option>
          </select>
          {errors && errors.preferred_time && errors.preferred_time.map((error, index) =>
            <span key={'errors-preferred_time-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
          )}
        </div>
        <div className="field">
          <label className="text-lumos-blue font-semibold">Phone # <span className="text-xs">(Optional unless its  your preferred contact method)</span></label>
          <PatternFormat placeholder="Phone Number" className="mt-2 w-full border border-lumos-blue block py-3 px-3 rounded-lg" valueIsNumericString format="###-###-####" mask="_" onChange={(e) => handleFormChange('phone', e.target.value)}/>
          {errors && errors.phone && errors.phone.map((error, index) =>
            <span key={'errors-phone-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
          )}
        </div>
        <div className="field">
          <label className="text-lumos-blue font-semibold">Additional Comments <span className="text-xs">Optional</span></label>
          <textarea type="text" onChange={(e) => handleFormChange('additional_comments', e.target.value)} placeholder="Additional Comments" className="mt-2 w-full border border-lumos-blue block py-3 px-3 rounded-lg"/>
          {errors && errors.comments && errors.comments.map((error, index) =>
            <span key={'errors-comments-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
          )}
        </div>
        <div className="button text-center mt-12 col-span-2">
          <button onClick={() => submitContactForm()} className="px-24 rounded text-white text-2xl py-4 bg-lumos-green">
            Submit Information
          </button>
        </div>
      </div>}
      {isSent && <div className="thanks my-20 text-center">
        <h1 className="text-lumos-green text-3xl px-20">
          Thanks for sharing your information. Someone on our team will reach to you to help you complete enrollment!
        </h1>
        <button onClick={() => setStage('plans')} className="px-24 rounded text-white text-2xl py-4 bg-lumos-blue mt-14">
          Return To Recommendations
        </button>
      </div>}
    </div>
  )
}

export default Contact