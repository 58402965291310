import logo from './assets/images/logo.svg';
import info from './assets/icons/info.svg';
import AboutYouLocation from './components/pages/AboutYouLocation';
import AboutYouInfo from './components/pages/AboutYouInfo';
import Savings from './components/pages/Savings';
import ProvidersCoverage from './components/pages/ProvidersCoverage';
import DrugsCoverage from './components/pages/DrugsCoverage';
import Usage from './components/pages/Usage';
import Other from './components/pages/Other';
import Save from './components/pages/Save';
import Results from './components/pages/Results';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Route, Routes, Navigate } from "react-router-dom"


function App() {

  const [currentStep, setCurrentStep] = useState('AboutYou');

  return (
    <div className="app">
      <header className="bg-lumos-blue flex justify-between items-center">
        <img src={logo} className="logo p-4" alt="logo" />
        <div className="left">
          <a href="#" className="text-white px-4 py-2 border mr-4 rounded-lg">Contact Us</a>
        </div>
      </header>
      <main>
          <Routes>
            <Route exact path="/" element={<Navigate replace to="/about/location"/>}></Route>
            <Route path="/about/location" element={<AboutYouLocation/>}/>
            <Route path="/about/info" element={<AboutYouInfo/>}/>
            <Route path="/savings" element={<Savings/>}/>
            <Route path="/coverage/providers" element={<ProvidersCoverage/>}/>
            <Route path="/coverage/drugs" element={<DrugsCoverage/>}/>
            <Route path="/usage" element={<Usage/>}/>
            <Route path="/other" element={<Other/>}/>
            <Route path="/other/save" element={<Save/>}/>
            <Route path="/results" element={<Results/>}/>
          </Routes>
          <div className="text-center px-3 mt-36 md:hidden">
            <h2 className="text-3xl text-lumos-blue font-semibold">This experience is designed for your desktop. <br/><br/> Mobile experience coming soon.</h2>
          </div>
      </main>
    </div>
  );
}

export default App;
