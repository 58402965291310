import axios from 'axios'
import Navigation from '../Navigation'
import info from '../../assets/icons/info.svg'
import React, { useEffect, useState } from 'react'
import { NumericFormat } from "react-number-format";
import { useSearchParams, useNavigate } from "react-router-dom"

function Savings(props){

  const [searchParams] = useSearchParams()
  const [state, setState] = useState({})
  const [taxCredits, setTaxCredits] = useState(null)
  const [totalPlans, setTotalPlans] = useState(null)
  const [stage, setStage] = useState('results')
  const [errors, setErrors] = useState({})
  const [people, setPeople] = useState(1)
  const [income, setIncome] = useState(null)
  let navigate = useNavigate()

  const stages = [
    'fill',
    'qualifies',
    'doesnt_qualify'
  ]

  useEffect(() => {
    const appState = decode(searchParams.get('state'))
    setState(appState)
    if(stateHasLocation(appState) && stateHasInfo(appState)){
      window.scrollTo(0, 0)
      updateRoute(appState)
    }else{
      previousRoute(navigate)
    }
  }, [])

  function stateHasLocation(appState){
    return appState && appState.location !== undefined && appState.location !== null
  }

  function stateHasInfo(appState){
    return appState && appState.primary &&
             appState.primary.age &&
             appState.primary.gender
  }

  function updateRoute(appState){
    if(appState.household_people){
      setPeople(appState.household_people)
    }
    if(appState.household_income){
      setIncome(appState.household_income)
    }
    if(appState.tax_credits){
      setTaxCredits(appState.tax_credits)
    }
    if(appState.total_plans){
      setTotalPlans(appState.total_plans)
    }
  }

  function nextRoute(navigate){
    if(people !== null && income !== null){
      const state = getState()
      const encodedState = encode(state)
      navigate('/coverage/providers?state='+encodedState)
    }
  }

  function previousRoute(navigate){
    const updatedState = getState()
    const encodedState = encode(updatedState)
    navigate('/about/info?state='+encodedState)
  }

  function getState(){
    const updatedState = state

    updatedState.household_people = people
    if(income){
      updatedState.household_income = income.replace(/\,/g,'')
    }
    updatedState.tax_credits = taxCredits
    updatedState.total_plans = totalPlans

    return updatedState
  }

  function encode(payload){
    return encodeURIComponent(JSON.stringify(payload))
  }

  function decode(payload){
    return JSON.parse(payload)
  }


  function handlePeopleChange(operation){
    if(people === ''){
      setPeople(1)
    }else{
      if(operation === 'inc'){
        if(people < 20){
          setPeople(parseInt(people) + 1)
        }
      }
      if(operation === 'dec'){
        if(people > 1){
          setPeople(parseInt(people) - 1)
        }
      }
    }
  }

  function reset(){
    window.scrollTo(0, 0)
    setTotalPlans(null)
    setTaxCredits(null)
    setStage('fill')
  }

  async function verifyQualification(){

    setErrors({})

    const parsedIncome = income === null || income === "" ? 0 : parseInt(income.replace(/\,/g,''))

    if(people >= 1 && people <=20 && parsedIncome >= 2000 && parsedIncome < 1000000){

      const updatedPeople = [
        {
          "age": parseInt(state.primary.age),
          "gender": state.primary.gender === 'M' ? "Male" : "Female",
          "has_mec": state.primary.aptc_eligible,
          "uses_tobacco": state.primary.uses_tobacco,
          "relationship": "Self"
        }
      ]

      if(people > 1){
        for (let i = 1; i < people; i++) {
          updatedPeople.push({
            "has_mec": true
          })
        }
      }

      const payload = {
        "household": {
          "income": parsedIncome,
          "people": updatedPeople
        },
        "market": "Individual",
        "place": {
          "countyfips": state.location.fips,
          "state": state.location.state.toString(),
          "zipcode": state.location.zipcode
        }
      }

      const auth = {
        params: {
          apikey: process.env.REACT_APP_CMS_API_KEY
        }
      }

      axios.post(process.env.REACT_APP_CMS_API_URL+'/households/eligibility/estimates', payload, auth).then(response => {
        if(response.status === 200){
          const estimates = response.data.estimates

          if(estimates[0].in_coverage_gap){
            setStage('coverage_gap')
          }else if(estimates[0].is_medicaid_chip){
            setStage('chip')
          }else{
            setStage('results')
          }

          setTaxCredits(estimates[0].aptc)

          axios.post(process.env.REACT_APP_CMS_API_URL+'/plans/search/stats', payload, auth).then(response => {
            const total = response.data.map(item => item.total).reduce((prev, next) => prev + next);
            setTotalPlans(total)
          })
        }
      })
    }else{
      const updatedErrors = {
        income: [],
        people: []
      }
      if(parsedIncome < 2000){
        updatedErrors.income.push("Must be higher than $2,000")
      }
      if(parsedIncome > 1000000){
        updatedErrors.income.push("Must be less than $1,000,000")
      }
      if(people <= 0){
        updatedErrors.people.push("Must be higher than 0")
      }
      if(people > 20){
        updatedErrors.people.push("Must be less than 20")
      }
      setErrors(updatedErrors)
    }
  }

  return (
    <div className="main hidden md:block form max-w-2xl mx-auto my-14">
      <Navigation/>
      {taxCredits === null && totalPlans === null && <div className="step px-5">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">Next, enter your household income information</h2>
          <p className="text-lg mt-2">We need this information to estimate your potential savings</p>
        </div>
        <div className="inputs px-16 my-10">
          <div className="flex justify-between items-center">
            <p className="font-semibold">How many people are in your household?</p>
            <div className="tooltip-container ml-auto">
                <div className="tooltip">
                    <span className="ml-auto"><img src={info} alt="Info"/></span>
                    <i className="icon ri-arrow-right-line" />
                  <span className="tooltip-drop tooltip-right">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                  </span>
                </div>
            </div>
          </div>
          <div className="input flex mt-2">
            <input value={people} onChange={(e) => setPeople(e.target.value)} min="1" max="20" type="number" className="w-full border border-lumos-blue block py-2 px-3 rounded-l-lg border-r-0"/>
            <button onClick={() => handlePeopleChange('dec')} className="border border-lumos-blue px-5 bg-lumos-gray text-xl">-</button>
            <button onClick={() => handlePeopleChange('inc')} className="rounded-r-lg border border-l-0 border-lumos-blue px-5 bg-lumos-gray text-xl">+</button>
          </div>
          <div className="input flex">
            {errors && errors.people && errors.people.map((error, index) =>
              <span key={'errors-people-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
            )}
          </div>
          <div className="flex mt-5 justify-between items-center">
            <p className="font-semibold">Estimate your 2023 household income (before taxes)</p>
            <div className="tooltip-container ml-auto">
                <div className="tooltip">
                    <span className="ml-auto"><img src={info} alt="Info"/></span>
                    <i className="icon ri-arrow-right-line" />
                  <span className="tooltip-drop tooltip-right">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  </span>
                </div>
            </div>
          </div>
          <div className="input flex mt-2">
            <span className="border flex items-center border-lumos-blue px-5 bg-lumos-gray text-xl rounded-l-lg">$</span>
            <NumericFormat
              type="text"
              value={income}
              onChange={(e) => setIncome(e.target.value)}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              decimalScale={0}
              allowNegative={false}
              className="w-full border border-lumos-blue block py-2 px-3 rounded-r-lg border-l-0"
            />
          </div>
          <div className="input flex">
            {errors && errors.income && errors.income.map((error, index) =>
              <span key={'errors-income-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
            )}
          </div>
        </div>
        <div className="actions flex items-center justify-between mt-20">
          <button onClick={() => previousRoute(navigate)} className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded">
            Back
          </button>
          <button onClick={() => {
              verifyQualification()
            }} className="w-64 p-4 bg-lumos-blue border border-lumos-blue text-white text-3xl rounded">
            Next
          </button>
        </div>
      </div>}
      {stage === "coverage_gap" && <div className="step px-5">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">You may be in the Medicaid gap</h2>
          <h3 className="text-xl text-lumos-blue mt-6">Based on your income, you may not qualify for any savings and your state may not cover you through Medicaid. Learn more about the Medicaid gap <a href="https://www.healthinsurance.org/faqs/what-is-the-medicaid-coverage-gap-and-who-does-it-affect/" className="underline">here</a> </h3>
          <div className="banner text-left border border-black mt-10 bg-lumos-light p-5 mx-4">
            <h2 className="font-bold text-xl">Your options:</h2>
            <h3 className="font-bold text-lg mt-5">Fill out an application through the Marketplace:</h3>
            <p>By providing more detailed information, you may find that you are eligible for Medicaid or if your income is higher, then you may be eligible for subsidies. Create an account and apply directly <a href="https://www.healthcare.gov/create-account" className="underline">here</a></p>

            <h3 className="font-bold text-lg mt-5">Continue with our tool to see recommended plans at <span className="underline">full</span> price</h3>
            <p>You can shop for full price plans by clicking Continue below</p>

            <h3 className="font-bold text-lg mt-5">Shop for lower priced “Catastrophic” plans</h3>
            <p>Catastrophic plans are lower cost but only protect you in worse-case scenarios. You need to pay for most routine medical expenses out-of-pocket but you’ll have access to some preventative services and a few free doctor visits. Learn more about how to qualify and apply for these plans <a href="https://www.healthcare.gov/health-coverage-exemptions/forms-how-to-apply/" className="underline">here</a></p>

            <h3 className="font-bold text-lg mt-5">Find and use low-cost / no-cost health centers</h3>
            <p>Free clinics and federally funded community health centers provide wide range of healthcare services, offering care on a sliding fee scale. Find a health center near you <a href="https://findahealthcenter.hrsa.gov/" className="underline">here</a></p>
        </div>
        </div>
        <div className="actions flex items-center justify-between mt-20">
          <button onClick={() => {
              reset()
            }} className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded">
            Back
          </button>
          <button onClick={() => nextRoute(navigate)} className="w-64 p-4 bg-lumos-blue border border-lumos-blue text-white text-3xl rounded">
            Continue
          </button>
        </div>
      </div>}
      {stage === "chip" && <div className="step px-5">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">You may be eligible for Medicaid and/or CHIP</h2>
          <div className="banner border border-black mt-6 bg-lumos-light p-5 mx-4">
            <span className="font-bold">
             Medicaid is a low-cost or free state administered health insurance plan available to people with low incomes or certain qualifying medical conditions
            </span>
            <h2 className="mt-10 font-thin text-lg">
              Learn more about Medicaid and how to apply using this healthcare.gov <a className="underline" href="https://www.healthcare.gov/medicaid-chip/getting-medicaid-chip/">link</a> 
            </h2>
        </div>
        </div>
        <div className="actions flex items-center justify-between mt-20">
          <button onClick={() => {
              reset()
            }} className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded">
            Back
          </button>
          <button disabled className="w-64 p-4 cursor-not-allowed bg-gray-600 text-white text-3xl rounded">
            Continue
          </button>
        </div>
      </div>}
      {!taxCredits && totalPlans && stage === "results" && <div className="step px-5">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">Your income may be too high to qualify for government tax credits  but you can still shop and compare plans</h2>
          <p className="text-lg mt-5">You qualify for over {totalPlans} ACA plans for you to shop and compare. Continue to find the plan the best meets your needs and lowers your true cost </p>
        </div>
        <div className="actions flex items-center justify-between mt-20">
          <button onClick={() => {
              reset()
            }} className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded">
            Back
          </button>
          <button onClick={() => nextRoute(navigate)} className="w-64 p-4 bg-lumos-blue border border-lumos-blue text-white text-3xl rounded">
            Continue
          </button>
        </div>
      </div>}
      {!!taxCredits && totalPlans && stage === "results" && <div className="step px-5">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">Good News! You may qualify for savings!</h2>
        </div>
        <div className="savings rounded-lg border border-lumos-blue py-8 px-12 mt-10">
          <p className="text-lg text-center font-semibold">You may save up this much on your premium due to government tax credits.</p>
          <h1 className="text-lumos-green text-5xl font-semibold text-center mt-10">${taxCredits} / month</h1>
          <p className="text-lg text-center font-semibold mt-10">This is only an estimate. You’ll need to finish applying with us to get your exact savings.</p>
        </div>
        <div className="banner border border-black mt-5 bg-lumos-light p-5">You qualify for over {totalPlans} ACA plans for you to shop, compare, and apply these savings. Continue to find the plan that’s right for you</div>
        <div className="actions flex items-center justify-between mt-10">
          <button onClick={() => {
            reset()
          }} className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded">
            Back
          </button>
          <button onClick={() => nextRoute(navigate)} className="w-64 p-4 bg-lumos-blue border border-lumos-blue text-white text-3xl rounded">
            Continue
          </button>
        </div>
      </div>}
  </div>
  )
}

export default Savings