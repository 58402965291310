import Select from 'react-select'
import Navigation from '../Navigation'
import info from '../../assets/icons/info.svg'
import React, { useEffect, useState } from 'react'
import { NumericFormat } from "react-number-format"
import formula from '../../assets/images/formula.png'
import { useSearchParams, useNavigate } from "react-router-dom"



function Coverage(props){

  const [searchParams] = useSearchParams()
  const [stage, setStage] = useState('last')
  const [state, setState] = useState({})
  const [errors, setErrors] = useState({})
  const [rainyDayFund, setRainyDayFund] = useState("")
  const [costTolerance, setCostTolerance] = useState("")
  let navigate = useNavigate()

  useEffect(() => {
    const appState = decode(searchParams.get('state'))
    setState(appState)
    if(stateHasLocation(appState) && 
        stateHasInfo(appState) && 
        stateHasSavings(appState) &&
        stateHasProviders(appState) &&
        stateHasDrugs(appState)){
      window.scrollTo(0, 0)
      updateRoute(appState)
    }else{
      previousRoute(navigate)
    }
  }, [])


  function updateRoute(appState){
    if(appState.rainy_day_fund){
      setRainyDayFund(appState.rainy_day_fund)
    }
    if(appState.cost_tolerance){
      setCostTolerance(appState.cost_tolerance)
    }
  }

  function stateHasLocation(appState){
    return appState && appState.location !== undefined && appState.location !== null
  }

  function stateHasInfo(appState){
    return appState && appState.primary &&
             appState.primary.age &&
             appState.primary.gender
  }

  function stateHasSavings(appState){
    return appState && appState.household_people &&
               appState.household_income
  }

  function stateHasProviders(appState){
    return appState && appState.providers &&
               appState.only_preferred_providers !== undefined
  }

  function stateHasDrugs(appState){
    return appState && appState.drugs &&
               appState.only_preferred_drugs !== undefined
  }
  
  function getState(){
    const updatedState = state

    if(rainyDayFund) updatedState.rainy_day_fund = rainyDayFund.replace(/\,/g,'')
    if(costTolerance) updatedState.cost_tolerance = costTolerance.replace(/\,/g,'')

    return updatedState
  }

  function validate(){
    const updatedErrors = []
    let hasErrors = false
    if(rainyDayFund === null || rainyDayFund === ""){
      updatedErrors.rainy_day_fund = ['Please provide this option']
      hasErrors = true
    }
    if(costTolerance === null || costTolerance === ""){
      updatedErrors.cost_tolerance = ['Please provide this option']
      hasErrors = true
    }
    setErrors(updatedErrors)
    if(!hasErrors){
      nextRoute(navigate)
    }else{
      window.scrollTo(0,100)
    }
  }
  
  function previousRoute(navigate){
    const updatedState = getState()
    const encodedState = encode(updatedState)
    navigate('/usage?state='+encodedState)
  }

  function nextRoute(navigate){
    const state = getState()
    const encodedState = encode(state)
    navigate('/other/save?state='+encodedState)
  }

  function decode(payload){
    return JSON.parse(payload)
  }

  function encode(payload){
    return encodeURIComponent(JSON.stringify(payload))
  }

  return (
    <div className="main hidden md:block form max-w-2xl mx-auto my-14">
      <Navigation/>
      <div className="step">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">Last Step! Answer the final two questions below before getting your personalized recommendations</h2>
        </div>
        <div className="px-4 mt-6">
          <p className="font-semibold inline text-lg">In a worst-case scenario, how much could you pay in out of pocket expenses, without having to borrow money on interest?</p>
        <div className="tooltip-container ml-1">
          <div className="tooltip">
              <span className="ml-auto"><img src={info} alt="Info"/></span>
              <i className="icon ri-arrow-right-line" />
            <span className="tooltip-drop tooltip-right">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </span>
          </div>
        </div>
        </div>
        <div className="input flex mt-4 px-4">
          <span className="border flex items-center border-lumos-blue px-5 bg-lumos-gray text-xl rounded-l-lg">$</span>
            <NumericFormat
              type="text"
              onChange={(e) => setRainyDayFund(e.target.value)}
              value={rainyDayFund}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              decimalScale={0}
              allowNegative={false}
              className="w-full border border-lumos-blue block py-2 px-3 rounded-r-lg border-l-0"
            />
        </div>
        <div className="input flex px-4">
          {errors && errors.rainy_day_fund && errors.rainy_day_fund.map((error, index) =>
            <span key={'errors-rainy-day-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
          )}
        </div>
        <div className="banner border border-black mt-6 bg-lumos-light p-5 mx-4">
          Why this matters? Plans with high deductibles can be cheaper but can require significant out of pocket expenses in a worst case scenario. If you don’t have the necessary funds to pay for medical expenses, you may need to borrow funds at high interest rates. We take this added potential cost into account in our true cost estimates and final recommendations.
        </div>
        <div className="px-4 mt-5">
          <p className="font-semibold inline text-lg">What’s the highest $ amount you’d be willing to pay for a PCP or specialist visit before reconsidering seeking treatment?</p>
        <div className="tooltip-container ml-1">
          <div className="tooltip">
              <span className="ml-auto"><img src={info} alt="Info"/></span>
              <i className="icon ri-arrow-right-line" />
            <span className="tooltip-drop tooltip-right">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </span>
          </div>
        </div>
        </div>
        <div className="input flex mt-4 px-4">
          <span className="border flex items-center border-lumos-blue px-5 bg-lumos-gray text-xl rounded-l-lg">$</span>
          <NumericFormat
            type="text"
            onChange={(e) => setCostTolerance(e.target.value)}
            value={costTolerance}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            decimalScale={0}
            allowNegative={false}
            className="w-full border border-lumos-blue block py-2 px-3 rounded-r-lg border-l-0"
          />
        </div>
        <div className="input flex px-4">
          {errors && errors.cost_tolerance && errors.cost_tolerance.map((error, index) =>
            <span key={'errors-cost-tolerance-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
          )}
        </div>
        <div className="banner border border-black mt-6 bg-lumos-light p-5 mx-4">
          Why this matters? Certain high deductible plans can be cheaper but may require you to pay the full cost of a PCP or specialist visit directly out of pocket, which can range from $40-$500 dependin on the doctor. If you are unable / unwilling to pay a high one-time visit fee to the doctor, you may avoid getting much needed care. We use your input above to show you additional recommendations that minimizes your true cost with the constraint that the cost of a one-time visit under the plan is lower than the maximum amount you’ve inputted above.
        </div>

        <div className="actions flex items-center justify-between mt-14">
          <button onClick={() => previousRoute(navigate)} className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded">
            Back
          </button>
          <button onClick={() => validate()} className="w-64 p-4 bg-lumos-blue border border-lumos-blue text-white text-3xl rounded">
            Next
          </button>
        </div>
      </div>
  </div>
  )
}

export default Coverage
