import _ from 'lodash'
import axios from 'axios'
import Select from 'react-select'
import classNames from 'classnames'
import Navigation from '../Navigation'
import info from '../../assets/icons/info.svg'
import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom"


function DrugsCoverage(props){

  const [searchParams] = useSearchParams()
  const [onlyPreferredDrugs, setOnlyPreferredDrugs] = useState(false)
  const [errors, setErrors] = useState({})
  const [search, setSearch] = useState('')
  const [expanded, setExpanded] = useState(null)
  const [drugOptions, setDrugOptions] = useState([])
  const [drugs, setDrugs] = useState([])
  const [state, setState] = useState({})
  let navigate = useNavigate()

  useEffect(() => {
    const appState = decode(searchParams.get('state'))
    setState(appState)
    if(stateHasLocation(appState) && stateHasInfo(appState) && stateHasSavings(appState)){
      window.scrollTo(0, 0)
      updateRoute(appState)
    }else{
      previousRoute(navigate)
    }
  }, [])

  function stateHasLocation(appState){
    return appState && appState.location !== undefined && appState.location !== null
  }

  function stateHasInfo(appState){
    return appState && appState.primary &&
             appState.primary.age &&
             appState.primary.gender
  }

  function stateHasSavings(appState){
    return appState && appState.household_people &&
               appState.household_income
  }

  function stateHasProviders(appState){
    return appState && appState.providers &&
               appState.only_preferred_providers !== undefined
  }

  function updateRoute(appState){
    if(appState.drugs){
      let updatedDrugs = appState.drugs.map(item => {
        item.expanded = false
        return item
      })
      setDrugs(updatedDrugs)
      setOnlyPreferredDrugs(appState.only_preferred_drugs)
    }
  }

  function getState(){
    const updatedState = state

    const optimizedDrugs = drugs.map(item => {
      return {
        rxcui: item.rxcui,
        name: item.name,
        selected_dose: item.selected_dose,
        supply: item.supply,
        rxnorm_dose_form: item.rxnorm_dose_form
      }
    })

    updatedState.drugs = optimizedDrugs
    updatedState.only_preferred_drugs = onlyPreferredDrugs

    return updatedState
  }
  
  function previousRoute(navigate){
    const updatedState = getState()
    const encodedState = encode(updatedState)
    navigate('/coverage/providers?state='+encodedState)
  }

  function validateDrugs(){

    const updatedErrors = []
    for (let i = 0; i < drugs.length; i++) {
      if(drugs[i].selected_dose == null || 
        drugs[i].selected_dose == undefined || 
        !drugs[i].supply){
        updatedErrors[drugs[i].rxcui] = 1
      }
    }
    setErrors(updatedErrors)
    if(!updatedErrors.length){
      nextRoute(navigate)
    }

  }

  function nextRoute(navigate){
    const state = getState()
    const encodedState = encode(state)
    navigate('/usage?state='+encodedState)
  }

  function decode(payload){
    return JSON.parse(payload)
  }

  function encode(payload){
    return encodeURIComponent(JSON.stringify(payload))
  }

  function handleDrugChange(field, index, value){
    let updatedDrugs = [...drugs]
    if(field === 'supply' || field === 'selected_dose' || field === 'expanded'){
      updatedDrugs[index][field] = value
    }
    setDrugs(updatedDrugs)
  }

  function pushToDrugs(drug){
      let updatedDrugs = [...drugs]
      drug.expanded = true
      updatedDrugs.push(drug)
      setDrugs(updatedDrugs)
      setDrugOptions([])
      setSearch("")
  }

  function removeFromDrugs(drug){
    const updatedDrugs = drugs.filter(item => {
      if(item.rxcui === drug.rxcui){
        return false
      }else{
        return true
      }
    })
    setDrugs(updatedDrugs)
  }

  function handleExpanded(index){
    if(index === expanded){
      setExpanded(null)
    }else{
      setExpanded(index)
    }
  }

  function autocomplete(e){
      setSearch(e.target.value)
      setExpanded(null)
      setDrugOptions([])
      setErrors({})
      if(e.target.value && e.target.value.length > 3){
        const query = e.target.value
        const payload = {
          params: {
            apikey: process.env.REACT_APP_CMS_API_KEY,
            q: query,
          }
        }
        const debouncedAutocomplete = _.debounce(() => {
          axios.get(process.env.REACT_APP_CMS_API_URL+'/drugs/autocomplete', payload).then(response => {
            if(response.status === 200){
              const results = response.data

              const updatedOptions = []

              for (let i = 0; i < results.length; i++) {
                let option = results[i]
                let existingIndex = _.findIndex(updatedOptions, function(o) {
                  return o.name === option.name && o.rxnorm_dose_form === option.rxnorm_dose_form
                })
                if(existingIndex === -1){
                  option.doses = [
                    option.strength
                  ]
                  updatedOptions.push(option)
                }else{
                  updatedOptions[existingIndex].doses.push(option.strength)
                }
              }
              setDrugOptions(updatedOptions)
            }
          })
        }, 500)
        debouncedAutocomplete()
      }
  }

  const options = [
    { value: 1, label: '1 Month' },
    { value: 2, label: '2 Months' },
    { value: 3, label: '3 Months' },
    { value: 4, label: '4 Months' },
    { value: 5, label: '5 Months' },
    { value: 6, label: '6 Months' },
    { value: 7, label: '7 Months' },
    { value: 8, label: '8 Months' },
    { value: 9, label: '9 Months' },
    { value: 10, label: '10 Months' },
    { value: 11, label: '11 Months' },
    { value: 12, label: '12 Months' }
  ]

  function getDefaultSupply(option){

    if(option.supply && option.supply !== undefined && option.supply !== null){
      const optionIndex = _.findIndex(options, (item) => {
        return item.value === option.supply
      })
      return options[optionIndex]
    }

  }

  var drugsClass = function(option, custom = ''){
    return classNames('cursor-pointer px-5 rounded-lg py-5 hover:bg-lumos-blue hover:text-white ' + custom, {
      'border': onlyPreferredDrugs !== option,
      'bg-lumos-blue text-white': onlyPreferredDrugs === option
    })
  }

  return (
    <div className="main hidden md:block form max-w-2xl mx-auto my-14">
      <Navigation/>
      <div className="step">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">Do you take any prescription drugs?</h2>
          <p className="text-lg mt-8">We’ll use this information to tailor our recommendations and show you which plans cover your drugs and for how much they’ll charge</p>
        </div>
        <div className="inputs px-16 mt-10">
          <div className="input flex mt-2">
            <input value={search} onChange={(e) => autocomplete(e)} type="text" placeholder="Enter a prescription name" className="w-full border border-lumos-blue block py-3 px-3 rounded-lg"/>
          </div>
        </div>
        <div className="results px-16">
          <div className="scroller border-lumos-blue overflow-scroll max-h-80">
            {drugOptions && !!drugOptions.length && drugOptions.map((option, index) => <div key={"drug-options-"+index} className={"result border cursor-pointer relative rounded-lg px-5 py-3 border-black border-t-0 flex items-center"}>
                <h2 className="text-lg font-semibold w-full">{option.name}: <span className="font-normal">{option.rxnorm_dose_form}</span></h2>
                <button onClick={() => {
                    pushToDrugs(option)
                  }} className="px-5 py-3 text-white bg-lumos-blue rounded text-sm">Add</button>
              </div>)}        
          </div>
        </div>
        <div className="selected mt-5 px-16">
          {drugs && !!drugs.length && drugs.map((option, index) => <div key={"drugs-"+index} className="result mt-2 border relative rounded-lg px-5 py-3 border-black">
              <h2 className="text-lg font-semibold" onClick={() => handleDrugChange('expanded', index, !option.expanded )}>{option.name}: <span className="font-normal">{option.rxnorm_dose_form}</span></h2>
              <span onClick={() => removeFromDrugs(option)} className="absolute top-1 right-3 text-lg cursor-pointer">&times;</span>
              <span>Supply: {option.supply ? option.supply + " Month(s)" : ""} | Dosage: {option.selected_dose}</span>
              {option.expanded == true && option.doses && option.doses.length && <div className="details">
                <p className="mt-2">Supply Duration</p>
                <Select
                  defaultValue={getDefaultSupply(option)}
                  onChange={(e) => handleDrugChange('supply', index, e.value)}
                  className="w-44 mt-2"
                  options={options}
                />
                <p className="mt-2">What's your dosage?</p>
                <Select
                  defaultValue={option.selected_dose ? {value: option.selected_dose, label: option.selected_dose} : null}
                  className="w-64 mt-2"
                  onChange={(e) => handleDrugChange('selected_dose', index, e.value)}
                  options={option.doses.map(dose => {
                    return {value: dose, label: dose}
                  })}
                />
                <div className="errors mt-2">
                  {errors[option.rxcui] == 1 && <span className="text-red-400">Please provide a valid supply duration and dosage</span>}
                </div>
              </div>}
            </div>)}        
        </div>
        {/*REMOVE "false &&" from the statement below to add back functionality*/}
        {false && drugs && !!drugs.length && <div className="recommend-options">
          <div className="items-center text-center mt-10">
            <p className="font-semibold inline text-lg">Do you want us to recommend plans that do not cover your preferred drugs?</p>
              <div className="tooltip-container ml-1">
                <div className="tooltip">
                    <span className="ml-auto"><img src={info} alt="Info"/></span>
                    <i className="icon ri-arrow-right-line" />
                  <span className="tooltip-drop tooltip-right">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  </span>
                </div>
              </div>
          </div>
          <div className="options grid grid-cols-2 gap-8 mt-10">
            <div onClick={() => setOnlyPreferredDrugs(true)} className={drugsClass(true)}>
              <p className="text-center">Only recommend plans that cover these preferences</p>
            </div>
            <div onClick={() => setOnlyPreferredDrugs(false)} className={drugsClass(false)}>
              <p className="text-center">I’m open to recommendations that do not cover these preferences</p>
            </div>
          </div>
          {onlyPreferredDrugs && <div className="banner border border-black mt-5 bg-lumos-light p-5 text-sm text-center">Gotcha! We’ll only recommend plans that cover these preferences but if you change your mind, you can update this setting later on. </div>}
          {!onlyPreferredDrugs && <div className="banner border border-black mt-5 bg-lumos-light p-5 text-sm text-center">Great! You may see recommended plans that do not cover all these preferences. If you change your mind, you’ll be able to update settings later on. </div>}
        </div>}
        <div className="actions flex items-center justify-between mt-16">
          <button onClick={() => previousRoute(navigate)} className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded">
            Back
          </button>
          <button onClick={() => validateDrugs(navigate)} className="w-64 p-4 bg-lumos-blue border border-lumos-blue text-white text-3xl rounded">
            {drugs && !!drugs.length && <span>Next</span>}
            {drugs && !drugs.length && <span>Skip this step</span>}
          </button>
        </div>
      </div>
  </div>
  )
}

export default DrugsCoverage
