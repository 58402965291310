function getAnnualGovTaxCredit(premium, subsidy){
  return commas(round(greater(premium-subsidy) * 12, 0))
}

function round(n, decimals = 2){
  return parseFloat(n).toFixed(decimals)
}

function greater(n, min = 0){
  return Math.max(min, n)
}

function getPaidByInsurance(plan, index){
  return commas(round(plan.consequence_graph[index].tot_expenditure - plan.consequence_graph[index].oop, 0))
}

function commas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function TrueCostBreakDown({plan, subsidy}){
  return (
      <div className="flex flex-col mt-5">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-center">
                <thead className="border-b">
                  <tr>
                    <th
                      scope="col"
                      className="text-sm font-medium text-white px-6 py-4"
                    >
                    </th>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white border-r px-6 bg-lumos-green py-4"
                    >
                      Lowest Usage
                    </th>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white border-r px-6 bg-lumos-blue py-4"
                    >
                      Low Usage
                    </th>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white border-r px-6 bg-lumos-yellow py-4"
                    >
                      Median Usage
                    </th>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white border-r px-6 bg-lumos-pink py-4"
                    >
                      High Usage
                    </th>
                    <th
                      scope="col"
                      className="text-xs font-medium text-white border-r px-6 bg-lumos-red py-4"
                    >
                      Highest Usage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border">
                    <td className="px-6 py-4 text-left whitespace-nowrap text-sm font-semibold text-gray-900 border-r">
                      Annual Premiums w/ Subsidy
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${getAnnualGovTaxCredit(plan.premium, subsidy)}
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${getAnnualGovTaxCredit(plan.premium, subsidy)}
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${getAnnualGovTaxCredit(plan.premium, subsidy)}
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${getAnnualGovTaxCredit(plan.premium, subsidy)}
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${getAnnualGovTaxCredit(plan.premium, subsidy)}
                    </td>
                  </tr>
                  <tr className="bg-white border">
                    <td className="px-6 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-400 border-r">
                      Total Medical Expenses
                    </td>
                    <td className="text-sm text-gray-400 border-r font-light px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[1].tot_expenditure)}
                    </td>
                    <td className="text-sm text-gray-400 border-r font-light px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[2].tot_expenditure)}
                    </td>
                    <td className="text-sm text-gray-400 border-r font-light px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[3].tot_expenditure)}
                    </td>
                    <td className="text-sm text-gray-400 border-r font-light px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[4].tot_expenditure)}
                    </td>
                    <td className="text-sm text-gray-400 border-r font-light px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[5].tot_expenditure)}
                    </td>
                  </tr>
                  <tr className="bg-white border">
                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm font-semibold text-gray-900 border-r">
                      Paid By You
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[1].oop)}
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[2].oop)}
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[3].oop)}
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[4].oop)}
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[5].oop)}
                    </td>
                  </tr>
                  <tr className="bg-white border">
                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm font-medium text-gray-400 border-r">
                      Paid By Insurance
                    </td>
                    <td className="text-sm text-gray-400 border-r font-light px-6 py-4 whitespace-nowrap">
                      ${getPaidByInsurance(plan, 1)}
                    </td>
                    <td className="text-sm text-gray-400 border-r font-light px-6 py-4 whitespace-nowrap">
                      ${getPaidByInsurance(plan, 2)}
                    </td>
                    <td className="text-sm text-gray-400 border-r font-light px-6 py-4 whitespace-nowrap">
                      ${getPaidByInsurance(plan, 3)}
                    </td>
                    <td className="text-sm text-gray-400 border-r font-light px-6 py-4 whitespace-nowrap">
                      ${getPaidByInsurance(plan, 4)}
                    </td>
                    <td className="text-sm text-gray-400 border-r font-light px-6 py-4 whitespace-nowrap">
                      ${getPaidByInsurance(plan, 5)}
                    </td>
                  </tr>
                  <tr className="bg-white border border-t-2 border-t-black">
                    <td className="px-6 py-4 text-left whitespace-nowrap text-sm font-semibold text-gray-900 border-r">
                      True Cost of Plan*
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[1].true_cost)}
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[2].true_cost)}
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[3].true_cost)}
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[4].true_cost)}
                    </td>
                    <td className="text-sm text-gray-900 border-r font-semibold px-6 py-4 whitespace-nowrap">
                      ${commas(plan.consequence_graph[5].true_cost)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="text-left mt-5">
                <span className="text-left font-light italic">
                  *True Cost Of Plan = Annual Premiums + Annual Out-of-Pocket Expenses (Paid By You)
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default TrueCostBreakDown