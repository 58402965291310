import axios from 'axios'
import Select from 'react-select'
import Navigation from '../Navigation'
import info from '../../assets/icons/info.svg'
import React, { useEffect, useState } from 'react'
import formula from '../../assets/images/formula.png'
import { useSearchParams, useNavigate } from "react-router-dom"


function Coverage(props){

  const [searchParams] = useSearchParams()
  const [state, setState] = useState({})
  const [errors, setErrors] = useState({})
  const [email, setEmail] = useState(null)
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()

  useEffect(() => {
    const appState = decode(searchParams.get('state'))
    setState(appState)
    if(stateHasLocation(appState) && 
        stateHasInfo(appState) && 
        stateHasSavings(appState) &&
        stateHasProviders(appState) &&
        stateHasDrugs(appState) &&
        stateHasOther(appState)){
      window.scrollTo(0, 0)
      updateRoute(appState)
    }else{
      previousRoute(navigate)
    }
  }, [])

  function updateRoute(appState){

    setState(appState)

  }

  function stateHasLocation(appState){
    return appState && appState.location !== undefined && appState.location !== null
  }

  function stateHasInfo(appState){
    return appState && appState.primary &&
             appState.primary.age &&
             appState.primary.gender
  }

  function stateHasSavings(appState){
    return appState && appState.household_people &&
               appState.household_income
  }

  function stateHasProviders(appState){
    return appState && appState.providers &&
               appState.only_preferred_providers !== undefined
  }

  function stateHasDrugs(appState){
    return appState && appState.drugs &&
               appState.only_preferred_drugs !== undefined
  }

  function stateHasOther(appState){
    return appState && appState.rainy_day_fund &&
               appState.rainy_day_fund !== undefined &&
               appState.cost_tolerance &&
               appState.cost_tolerance !== undefined
  }
  
  function getState(){
    const updatedState = state
    return updatedState
  }

  function validate(){
    const updatedErrors = []
    let hasErrors = false
    setErrors(updatedErrors)
    if(!hasErrors){
      // setStage('save')
    }else{
      window.scrollTo(0,100)
    }
  }
  
  function previousRoute(navigate){
    //add state
    const updatedState = getState()
    const encodedState = encode(updatedState)
    navigate('/other?state='+encodedState)
  }

  function nextRoute(navigate){
    const state = getState()
    const encodedState = encode(state)
    navigate('/results?state='+encodedState)
  }

  function decode(payload){
    return JSON.parse(payload)
  }

  function encode(payload){
    return encodeURIComponent(JSON.stringify(payload))
  }

  const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  function saveProgress(navigate){
    const updatedErrors = {};
    if (!isEmail(email)) {
      updatedErrors.email = ["Please provide a valid email"];
      setErrors(updatedErrors);
    }else{
      setLoading(true)
      axios.post(process.env.REACT_APP_API_URL+'/saveprogress', {
        'email': email,
        'url': window.location.href
      }).then(response => {
        if(response.status === 200){
          nextRoute(navigate)
          setLoading(false)
        }
      })
    }
  }

  return (
    <div className="main hidden md:block form max-w-2xl mx-auto my-14">
      <Navigation/>
      <div className="step">
        <div className="title info mt-10 text-center">
          <h2 className="text-3xl text-lumos-blue font-semibold">You’re one click away from your personalized recommendations! We recommend you save your progress.</h2>
        </div>
        <div className="inputs px-16 my-10">
          <div className="input flex mt-2">
            <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder="Enter your email address" className="w-full border border-lumos-blue block py-3 px-3 rounded-lg text-center font-semibold"/>
          </div>
          <div className="input flex">
            {errors && errors.email && errors.email.map((error, index) =>
              <span key={'errors-rainy-day-'+index} className="text-red-500 mt-2 block">{error}</span>
            )}
          </div>
        </div>

        <div className="actions px-16 text-center mt-5">
          <button onClick={() => saveProgress(navigate)} className="w-full p-4 bg-lumos-green-dark border border-lumos-green-dark text-white text-xl rounded flex justify-center items-center">
            <span>
              Save My Progress and Get Recommendations
            </span>
            {loading && <span>
              <img className="inline w-8 ml-3" src="/loading.gif" alt=""/>
            </span>}
          </button>
          <a onClick={() => nextRoute(navigate)} className="text-lumos-blue mt-8 cursor-pointer block">
            Skip to Recommendations ->
          </a>
        </div>
      </div>
  </div>
  )
}

export default Coverage
